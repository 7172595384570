import { fetchWithErrorThrowing } from 'ck-utils';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

type PostValidateResetPasswordTokenRequestV1 = {
  token: string | undefined;
};

type ValidateResetPasswordTokenResponse = {
  isValid: boolean;
};

export const useValidateResetPasswordToken = () => {
  const navigation = useNavigate();
  return useMutation({
    mutationFn: (data: PostValidateResetPasswordTokenRequestV1) =>
      toast.promise(
        postValidateResetPasswordToken(data),
        {
          loading: 'Laddar...',
          success: 'Tema borttaget',
          error: 'Kunde inte ta bort temat',
        },
        {
          duration: 8000,
        }
      ),
    onSuccess: (responseData) => {
      // If the token is invalid, show an error message and redirect to login page
      if (!responseData.isValid) {
        toast.error(
          'Länken har antingen gått ut eller är ogiltig. Försök igen.'
        );
        navigation('/login');
      }
    },
  });
};

async function postValidateResetPasswordToken(
  data: PostValidateResetPasswordTokenRequestV1
): Promise<ValidateResetPasswordTokenResponse> {
  const res = await fetchWithErrorThrowing('/password/validate-reset-token', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({ 'content-type': 'application/json' }),
  });

  const result: ValidateResetPasswordTokenResponse = await res.json();
  return result;
}
