import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

interface FormLabelProps<T extends FieldValues> {
  label: string;
  description?: string;
  charCount?: number;
  maxLength?: number | undefined;
  parentForm?: UseFormReturn<T>;
  name: Path<T>;
}

/**
 * A wrapper component for the label component with custom styling.
 * @param {FormLabelProps} props - The props for the FormLabelNew component.
 * @param {string} props.label - The text to be displayed as the label.
 * @param {string} props.description - Optional text to be displayed as a description under the label.
 * @param {number} props.charCount - The current character count of the input field.
 * @param {number|undefined} props.maxLength - The maximum number of characters allowed in the input field.
 * @returns {JSX.Element|null} The JSX element for the FormLabelNew component, or null if no label is provided.
 */
export const FormLabelNew = <T extends FieldValues>({
  label,
  description,
  parentForm,
  maxLength,
  name,
}: FormLabelProps<T>) => {
  const value = parentForm?.watch(name);
  const charCount = value?.length || '0';

  return (
    <label
      htmlFor={name}
      className='flex w-full flex-col content-start items-start'
    >
      <div className='flex w-full'>
        <div className='mb-2 mr-2 flex h-full flex-1 flex-col text-left text-base font-light text-gray-800'>
          <div className='whitespace-nowrap uppercase tracking-custom'>
            {label}
          </div>
          {description && (
            <div className='mt-1 w-full overflow-ellipsis  text-sm text-gray-400'>
              {description}
            </div>
          )}
        </div>
        {parentForm && charCount && maxLength && (
          <div className='mb-2 flex items-end justify-end whitespace-nowrap text-left text-base font-light tracking-custom text-gray-400 '>
            {`${charCount}/${maxLength}`} tecken
          </div>
        )}
      </div>
    </label>
  );
};
