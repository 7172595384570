import { createFormData, fetchWithErrorThrowing } from 'ck-utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GK } from '../queryKeys';
import { PostPollsRequest } from './types';
import toast from 'react-hot-toast';

// Mutation hook
export const usePollsEdit = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PostPollsRequest) =>
      toast.promise(
        putPollsEdit(data, id),
        {
          loading: 'Laddar...',
          success: 'Omröstning redigerad',
          error: 'Kunde inte redigera omröstning',
        },
        {
          duration: 8000,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(GK.polls);
    },
  });
};
// Mutation function
const putPollsEdit = (data: PostPollsRequest, id: string) => {
  const { targetGroup } = data;

  const formData = createFormData({ ...data, targetGroup: targetGroup.group });

  return fetchWithErrorThrowing(`/polls/${id}`, {
    method: 'PUT',
    body: formData,
  });
};
