import { QK, SwishStore } from 'ck-queries';

import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useCommitteesSwishStores = (
  committeeId: string,
  shouldFetch: boolean
) => {
  return useQuery({
    queryKey: [QK.swishStores, committeeId],
    queryFn: () => getCommitteesSwishStores(committeeId),
    enabled: shouldFetch,
  });
};

// Query function
async function getCommitteesSwishStores(
  committeeId: string
): Promise<SwishStore> {
  const res = await fetchWithErrorThrowing(
    `/committees/${committeeId}/swish-store`,
    {
      method: 'GET',
    },
    true
  );

  const data = await res.json();
  return data;
}
