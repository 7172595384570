import { Admin, QK } from 'ck-queries';

import { AdminFromServer } from './types';
import { fetchWithErrorThrowing } from '../../utils/fetchWithErrorThrowing';
import { formatStatus } from './localUtils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useAdmins = (divisionId: string) => {
  return useQuery({
    queryKey: [QK.admins, divisionId],
    queryFn: () => getAdmins(divisionId),
  });
};

// Query function
async function getAdmins(divisionId: string) {
  const res = await fetchWithErrorThrowing(`/divisions/${divisionId}/admins`, {
    method: 'GET',
  });

  const data: { admins: AdminFromServer[] } = await res.json();

  return formatAdmins(data.admins);
}

// Query formatter
const formatAdmins = (admins: AdminFromServer[]) => {
  return admins.map((a) => {
    // Format status to include expire date
    const status = formatStatus(a.status, a.expireDate, null);

    return { ...a, status } as Admin;
  });
};
