import { Tab } from '@headlessui/react';
import clsx from 'clsx';

interface Props {
  label: string;
}

/**
 * Simple styled title for tabs in headlessUI
 */

export const TabTitle = ({ label }: Props) => {
  return (
    <Tab className='outline-none'>
      {({ selected }) => (
        <h3
          className={clsx(
            '-mb-0.5 border-b-2 border-neutral-200 py-2 text-base font-bold tracking-custom',
            selected && ' border-[#00ACFF]'
          )}
        >
          {label}
        </h3>
      )}
    </Tab>
  );
};
