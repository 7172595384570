import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QK } from 'src/queries';
import { fetchWithErrorThrowing } from '../../utils/fetchWithErrorThrowing';

// Mutation hook
export const useCreateAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAdmin,
    onSuccess: () => {
      // Invalidate the admin list so that the new admin shows up
      queryClient.invalidateQueries({
        queryKey: [QK.admins],
      });
    },
  });
};

// Mutation function
function createAdmin({ id, email }: { id: string; email: string }) {
  return fetchWithErrorThrowing('/divisions/invite-admin', {
    method: 'POST',
    body: JSON.stringify({ divisionId: id, email: email }),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
}
