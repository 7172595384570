import { fetchWithErrorThrowing, standardDateFormat } from 'src/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GK } from '../queryKeys';
import { PostPushNotification } from './types';
import dayjs from 'dayjs';
import { toast } from 'react-hot-toast';

export const useNotificationsEdit = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PostPushNotification) =>
      toast.promise(
        editScheduledNotification(data, id),
        {
          loading: 'Laddar...',
          success: 'Pushnotisen är uppdaterad',
          error: 'Kunde inte redigera pushnotis',
        },
        {
          duration: 8000,
        }
      ),
    // If connection is lost we don't want it to queue up mutations
    networkMode: 'always',
    onSuccess: (_, query) => {
      !query.isDraft &&
        toast.success(
          query.scheduleTime
            ? `Pushnotis schemalagd \ntill ${dayjs(query.scheduleTime).format(
                standardDateFormat
              )}`
            : 'Pushnotis skickad'
        );

      // Invalidate all notifications queries to update the UI
      queryClient.invalidateQueries(GK.notifications);
    },
  });
};

async function editScheduledNotification(
  { targetGroup: { group: name }, ...data }: PostPushNotification,
  id: string
) {
  return fetchWithErrorThrowing(`/notifications/scheduled/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ ...data, targetGroup: name }), // API only accepts a string of the name
    headers: new Headers({ 'content-type': 'application/json' }),
  });
}
