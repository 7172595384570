// Helper function used when converting an image file

// uploaded by the user to a string URL that can be used for preview
export function convertFileToUrlString(file: File | FileList | null) {
  // NOTE: When an image upload input is cleared, the value is set to FileList. That's why we need to check for that here.

  // Check that file is actually a file
  if (!file || file instanceof FileList) return Promise.resolve(null);

  const reader = new FileReader();

  return new Promise<string | null>((resolve) => {
    reader.onload = () => {
      const urlString = reader.result as string;
      resolve(urlString);
    };

    if (file instanceof File) {
      reader.readAsDataURL(file);
    }
  });
}
