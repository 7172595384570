export type ErrorMessages = { [key: string]: Array<string> };

export interface APIErrorParams {
  title: string;
  status: number;
  clientMessageSwedish: string;
  developerMessage: string;
  errorType: string;
  errors?: ErrorMessages;
}

export class APIError extends Error {
  title: string;
  status: number;
  ok = false as const;
  clientMessageSwedish: string;
  developerMessage: string;
  errorType: string;
  errors: ErrorMessages | undefined;

  constructor(error: APIErrorParams) {
    super(error.clientMessageSwedish);
    this.name = error.title;
    this.title = error.title;
    this.status = error.status;
    this.clientMessageSwedish = error.clientMessageSwedish;
    this.developerMessage = error.developerMessage;
    this.errors = error.errors;
    this.errorType = error.errorType;
  }
}
