import {
  ConfirmationModal,
  LoadingSpinner,
  TableWithPagination,
} from 'ck-components';
import { EventsParticipants, SwishStore } from 'ck-queries';
import {
  QueryKey,
  UseMutationOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { testIds } from 'src/testIds';
import { toast } from 'react-hot-toast';
import { useState } from 'react';

type QueryConfig<TData> = {
  queryKey: QueryKey;
  queryFn?: () => Promise<TData>;
};

type TableStructure = {
  title: string;
  dataPath: string;
};

interface Props<TData extends Array<any>> {
  emptyStateMessage: string;
  queryConfig: QueryConfig<TData>;
  mutationConfig?: UseMutationOptions<Response, unknown, string>;
  tableStructure: TableStructure[];
  customDataType?: any;
  onRowClick?: (data: any) => void;
  onEditClick?: (data: any) => void;
  onCopyClick?: (data: any) => void;
  onCustomPress?: (data: any) => void;
  onDownloadClick?: (data: any) => void;
  customLastColumn?: React.ReactNode;
}

interface DefaultData {
  id: string;
  title: string;
  nameSwedish: string;
  titleSwedish: string;
}

/**
 * Generic archive tab component with table and pagination
 * Uses generic queryConfig to fetch data and mutationConfig to delete data
 *
 * @param emptyStateMessage: Message to display if no data is found
 * @param tableStructure: Structure of table to display. Title and dataPath.
 * @param queryConfig: Query config to fetch data
 * @param mutationConfig: Mutation config to delete data
 * @param onRowClick: Callback to run when row is clicked. Is passed the row data.
 * @param onEditClick: Callback to run when edit button is clicked. Is passed the row data.
 * @param onCopyClick: Callback to run when copy button is clicked. Is passed the row data.
 * @param onDownloadClick: Callback to run when download button is clicked. Is passed the row data.
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ArchiveTab = <TData extends Array<any>>({
  emptyStateMessage,
  tableStructure,
  queryConfig,
  mutationConfig,
  onRowClick,
  onEditClick,
  onCopyClick,
  customLastColumn,
  onCustomPress,
  onDownloadClick,
}: Props<TData>) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [idToDelete, setIdToDelete] = useState<string | null>();
  const [titleOfDelete, setTitleOfDelete] = useState<string>();

  // Generic mutation to delete table row
  const mutation = useMutation(mutationConfig || {});

  // Generic query to get table data
  const query = useQuery(queryConfig);

  if (query.isLoading) {
    return (
      <div className='mt-10'>
        <LoadingSpinner
          width={100}
          data-testid={testIds.other.loadingSpinner}
        />
      </div>
    );
  }

  if (query.isError) {
    return <p className='py-4 text-lg'>Error...</p>;
  }

  if (query.data.length === 0) {
    return <p className='py-4 text-lg'>{emptyStateMessage}</p>;
  }

  return (
    <>
      {idToDelete && (
        <ConfirmationModal
          isOpen={showDeleteConfirmationModal}
          setIsOpen={setShowDeleteConfirmationModal}
          onConfirmation={() => {
            mutation.mutate(idToDelete);
            setIdToDelete(null);
          }}
          isDelete
          confirmationLabel='Ta bort'
          modalTitle={`Radera ${titleOfDelete}`}
        >
          <p className='max-w-sm text-lg'>
            Är du säker på att du vill ta bort {titleOfDelete}?
          </p>
        </ConfirmationModal>
      )}
      <TableWithPagination
        data={query.data}
        elementsPerPage={10}
        tableColHeadings={tableStructure.map(({ title }) => title)}
        tableRowDataPaths={tableStructure.map(({ dataPath }) => dataPath)}
        onRowPress={onRowClick ? (data: any) => onRowClick(data) : undefined}
        onDeletePress={
          mutationConfig
            ? (data: DefaultData | SwishStore | EventsParticipants) => {
                if ('id' in data) {
                  if (data.id) {
                    setIdToDelete(data.id);

                    // ! This is a bit messy, but it works for now
                    // TODO: Make entire onDeletePress a prop instead
                    setTitleOfDelete(
                      data.title
                        ? data.title
                        : data.nameSwedish
                        ? data.nameSwedish
                        : data.titleSwedish
                    );
                    setShowDeleteConfirmationModal(true);
                  } else {
                    toast.error(
                      'Inget id kunde hittas. Prova uppdatera webbläsaren.'
                    );
                  }
                } else {
                  if ('swishStoreId' in data) {
                    setIdToDelete(data.swishStoreId);
                    setTitleOfDelete('swishkassan');
                    setShowDeleteConfirmationModal(true);
                  } else {
                    if ('eventId' in data) {
                      setIdToDelete(data.eventId);
                      setTitleOfDelete('deltagarlistan');
                      setShowDeleteConfirmationModal(true);
                    } else {
                      toast.error(
                        'Inget id kunde hittas. Prova uppdatera webbläsaren.'
                      );
                    }
                  }
                }
              }
            : undefined
        }
        onEditPress={onEditClick ? (data: any) => onEditClick(data) : undefined}
        onCopyPress={onCopyClick ? (data: any) => onCopyClick(data) : undefined}
        onDownloadPress={
          onDownloadClick ? (data: any) => onDownloadClick(data) : undefined
        }
        onCustomPress={
          onCustomPress ? (data: any) => onCustomPress(data) : undefined
        }
        customLastColumn={customLastColumn}
      />
    </>
  );
};
