import { ChevronDown, ChevronUp } from 'src/assets';
import { Link, useLocation } from 'react-router-dom';
import { createContext, useCallback, useState } from 'react';

import { PageName } from 'src/typesGlobal';
import { ReactSVG } from 'react-svg';
import { checkUrlMatch } from 'src/utils';
import clsx from 'clsx';

interface NavLinkProps {
  href: PageName;
  label: string;
  children?: React.ReactNode;
  icon?: string;
}

interface SubLinkContextProps {
  registerNewSubLink: (href: string) => void;
}

/**
 * This is the main clickable link in the big navigation bar.
 * We only want the text color to be changed to blue if there are no sublinks.
 *
 * @param href the url to the page we want to redirect to
 * @param children the nested sublinks, should there be any
 * @param label the clickable text
 */

export const RegisterSubLinkContext = createContext<SubLinkContextProps>({
  registerNewSubLink: () => undefined,
});

export function NavLink({ href, children, label, icon }: NavLinkProps) {
  const location = useLocation();
  const [registeredSubLinks, registerSubLink] = useState<string[]>([]);

  const showChildLinks = registeredSubLinks.some((subLinkHref) =>
    checkUrlMatch(subLinkHref, location.pathname)
  );

  const isClicked =
    (children === undefined && location.pathname === href) ||
    registeredSubLinks.some((sublinkHref) =>
      checkUrlMatch(sublinkHref, location.pathname)
    ) ||
    checkUrlMatch(href, location.pathname);

  const registerNewSubLink = useCallback(
    (href: string) => {
      if (!registeredSubLinks.some((sublinkHref) => sublinkHref === href)) {
        registerSubLink((oldState) => [...oldState, href]);
      }
    },
    [registeredSubLinks]
  );

  const getTextColor = () => {
    return isClicked ? 'text-sky-500' : 'text-black';
  };

  return (
    <li className='flex'>
      <div className='flex w-full flex-col gap-4'>
        <Link
          to={href}
          className={clsx('text-xl font-semibold', getTextColor())}
        >
          <div className='flex gap-12'>
            <div
              className={clsx(
                'h-10 rounded-tr-xl rounded-br-xl border-r-[5px]',
                isClicked ? 'border-sky-500' : 'invisible'
              )}
            />
            <div className='mr-5 flex w-full items-center justify-between'>
              <div className='flex items-center gap-4'>
                {icon && <ReactSVG src={icon} className='w-5' />}
                {label}
              </div>
              <div>
                {children && !isClicked && (
                  <ReactSVG src={ChevronDown} className='ml-3 w-6 text-black' />
                )}
                {children && isClicked && (
                  <ReactSVG src={ChevronUp} className='ml-3 w-6  text-black' />
                )}
              </div>
            </div>
          </div>
        </Link>
        <RegisterSubLinkContext.Provider value={{ registerNewSubLink }}>
          <div className={!showChildLinks ? 'hidden' : 'visible'}>
            <div className='ml-[90px] flex flex-col gap-5 pb-5'>{children}</div>
          </div>
        </RegisterSubLinkContext.Provider>
      </div>
    </li>
  );
}
