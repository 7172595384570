import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form';

import { DropdownButton } from './DropdownButton';
import { DropdownList } from './DropdownList';
import { ErrorMessages } from '../errormessages/ErrorMessages';
import { FormLabelNew } from '../FormLabel/FormLabelNew';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import { useEffect } from 'react';

// TODO: Make generic for other forms of objects
// Currently only made for targetGroup selection
export type DropdownOption = {
  group: string;
  count: string;
};

interface Props<T extends FieldValues> {
  parentForm: UseFormReturn<T>;
  optionsData: DropdownOption[];
  name: Path<T>;
  disabled?: boolean | undefined;
  horizontal?: boolean | undefined;
  label?: string;
  description?: string;
  errorsAlwaysTakeUpSpace?: boolean;
  emptyStateLabel?: string;
  multiple?: boolean;
  optional?: boolean;
  onChange?: (selected: DropdownOption) => void;
  defaultValue: DropdownOption;
}

/**
 * DropdownSelector to pick a label-value pair.
 * Needs more work before fully generalized.
 */
export const DropdownSelector = <T extends FieldValues>({
  optionsData,
  name,
  parentForm,
  disabled,
  defaultValue,
  description,
  label,
  emptyStateLabel = label ? `Välj ${label.toLowerCase()}` : '',
  errorsAlwaysTakeUpSpace = true,
  onChange,
}: Props<T>) => {
  const { control, formState, watch, setValue } = parentForm;
  const valueInForm = watch(name);
  const selectedOption = watch(name);

  useEffect(() => {
    if (!valueInForm) {
      setValue(name, defaultValue as PathValue<T, Path<T>>);
    }
  }, [defaultValue, name, setValue, valueInForm]);

  if (!selectedOption) return null;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={selectedOption}
      render={() => (
        <div>
          <div className={clsx('relative w-full', !selectedOption && '-mb-1')}>
            <Listbox
              value={selectedOption}
              disabled={disabled}
              onChange={
                onChange ? onChange : (selected) => setValue(name, selected)
              }
            >
              {({ open }) => (
                <>
                  {label && (
                    <FormLabelNew
                      label={label}
                      description={description}
                      parentForm={parentForm}
                      name={name}
                    />
                  )}
                  <DropdownButton
                    name={name}
                    emptyStateLabel={emptyStateLabel}
                    disabled={disabled}
                    displayLabel={`${selectedOption.group} (${selectedOption.count} st)`}
                    open={open}
                  />
                  <DropdownList
                    options={optionsData}
                    selectedOption={selectedOption}
                  />
                  <ErrorMessages
                    errors={formState.errors}
                    name={name}
                    takeUpSpace={errorsAlwaysTakeUpSpace}
                  />
                </>
              )}
            </Listbox>
          </div>
        </div>
      )}
    />
  );
};
