import {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';
import {
  getMaxLengthOption,
  getMinLengthOption,
  getRequiredOption,
} from './errormessages/errorOptions';

import { ErrorMessages } from './errormessages/ErrorMessages';
import { FormLabelNew } from './FormLabel/FormLabelNew';
import React from 'react';
import clsx from 'clsx';

interface FormMultiLineTextInputProps<T extends FieldValues>
  extends React.HTMLProps<HTMLTextAreaElement> {
  parentForm: UseFormReturn<T>;
  optional?: boolean;
  description?: string;
  options?: RegisterOptions;
  name: Path<T>;
}

/**
 * A custom wrapper for the HTML textarea input component. Extends the functionality with some basic error handling as
 * provided by the react-hook-form.
 *
 * @param props Props for the custom Form Input component. Also accepts all 'normal' props for the HTML textarea component.
 * @param props.name The name of the of the field to be registered
 * @param props.register The register function from react-hook-form
 * @param props.options An object containing the options you can pass in to the register function
 * from react-hook-form.
 * @param props.errors The errors object as extracted from the formState of react-hook-form
 */

export const FormMultiLineTextInput = <T extends FieldValues>({
  name,
  optional,
  options = {},
  disabled = false,
  readOnly = false,
  parentForm,
  label,
  maxLength,
  minLength,
  description,
  ...rest
}: FormMultiLineTextInputProps<T>) => {
  const { formState, register } = parentForm;

  return (
    <>
      {label && (
        <FormLabelNew
          name={name}
          label={label}
          description={description}
          parentForm={parentForm}
          maxLength={maxLength}
        />
      )}
      <textarea
        {...register(name, {
          required: getRequiredOption(optional),
          maxLength: getMaxLengthOption(maxLength, label),
          minLength: getMinLengthOption(minLength, label),
          ...options,
        })}
        id={name}
        maxLength={maxLength}
        {...rest}
        className={clsx(
          'h-36 w-full resize-none rounded border border-solid border-gray-300 px-3 pt-3 pl-4 focus:outline-sky-500',
          disabled &&
            'cursor-not-allowed bg-neutral-50 text-gray-400 placeholder:text-gray-400 hover:border-gray-300 focus:border-gray-300',
          readOnly &&
            'border-none bg-inherit caret-transparent hover:cursor-default focus:border-none focus:outline-none'
        )}
      />

      <ErrorMessages errors={formState.errors} name={name} />
    </>
  );
};
