import { fetchWithErrorThrowing, standardDateFormat } from 'ck-utils';

import { EventObj } from './types';
import dayjs from 'dayjs';

export async function getEvents(
  type: 'active' | 'history'
): Promise<EventObj[]> {
  const res = await fetchWithErrorThrowing(`/events/${type}`, {
    method: 'GET',
  });

  const data: { events: EventObj[] } = await res.json();

  const formatted = data.events.map((el) => {
    return {
      ...el,
      startTime: dayjs(el.startTime).format(standardDateFormat),
      endTime: dayjs(el.endTime).format(standardDateFormat),
      updatedAt: dayjs(el.updatedAt).format(standardDateFormat),
      createdBy: el.createdBy ? el.createdBy : 'Tidigare formulär',
    };
  });

  return formatted;
}

export async function deleteEvent(eventId: string) {
  return fetchWithErrorThrowing(`/events/${eventId}`, {
    method: 'DELETE',
  });
}

export async function deleteParticipantsList(eventId: string) {
  return fetchWithErrorThrowing(`/events/${eventId}/participants`, {
    method: 'DELETE',
  });
}
