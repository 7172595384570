import { CancelButton, ConfirmButton } from 'ck-components';

import { ConfirmationModalProps } from '../types';
import Modal from './Modal';

/**
 * This confirmation modal renders the children props as a body of the modal.
 * It also renders two buttons at the bottom (accept and cancel). These are called when one of the
 * modal buttons are pressed. This component can therefore be used when the user need to verify an action.
 *
 * @param cancelLabel the text to be displayed on the cancel button
 * @param confirmationLabel the text to be displayed on the confirmation button
 * @param onCancel the callback called when the user presses the cancel button
 * @param onConfirmation the callback called when the user presses the confirmation button
 * @param modalTitle the main title of the modal, displayed in bold letters at the top
 * @param isOpen denotes whether or not the modal is currently visible
 * @param children the child components rendered within the modal body
 * @param customStyle optional custom styling (can be used to for example set a custom max-width)
 * @param isDelete optional boolean to set the color of the buttons to red
 */

export function ConfirmationModal({
  cancelLabel = 'Avbryt',
  confirmationLabel = 'Okej',
  onCancel = () => undefined,
  onConfirmation = () => undefined,
  setIsOpen,
  modalTitle,
  isOpen,
  children,
  customStyle,
  isDelete,
  isLoading,
}: ConfirmationModalProps) {
  const onConfirmationProxy = () => {
    onConfirmation();
    setIsOpen(false);
  };

  const onCancelProxy = () => {
    onCancel();
    setIsOpen(false);
  };

  return (
    <Modal
      modalTitle={modalTitle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customStyle={customStyle}
    >
      <div className=' pt-4 pb-9'>{children}</div>

      <div className='flex justify-center'>
        <div className='flex gap-10'>
          <CancelButton
            label={cancelLabel}
            onClick={onCancelProxy}
            className='w-44'
            isDelete={isDelete}
          />

          <ConfirmButton
            label={confirmationLabel}
            onClick={onConfirmationProxy}
            className='w-44'
            isDelete={isDelete}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
}
