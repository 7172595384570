import React, { ButtonHTMLAttributes } from 'react';

import { ReactSVG } from 'react-svg';
import { clsx } from 'clsx';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  isCancelButton?: boolean;
  customStyle?: string;
  className?: string;
  iconLeft?: string;
  iconStyle?: string;
}

/**
 *
 * @param onClick the callback function for when the button is clicked
 * @param label the text displayed on the button
 * @param disabled decides whether the button is disabled or not
 * @param buttonType decides the styling of the button
 * @param customStyle additional custom styling
 * @param iconLeft the icon to be displayed on the left of the button
 * @param iconStyle additional custom styling for the icon
 * @returns
 */

export function Button({
  onClick,
  label,
  disabled = false,
  isCancelButton = false,
  customStyle,
  iconLeft,
  iconStyle,
}: ButtonProps) {
  return (
    <button
      type='button'
      onClick={disabled ? () => undefined : () => onClick()}
      disabled={disabled}
      className={clsx(
        'flex items-center justify-center gap-1 rounded-2xl border border-sky-500 bg-sky-500 px-5 py-3 text-xl font-semibold  text-white hover:border-sky-600 hover:bg-sky-600 active:border-sky-500 active:bg-sky-500 disabled:cursor-not-allowed disabled:border-sky-300 disabled:bg-sky-300 disabled:text-sky-100',
        isCancelButton &&
          'bg-white text-sky-500 hover:border-sky-600 hover:bg-white hover:text-sky-600  active:bg-white active:text-sky-500 disabled:bg-white disabled:text-sky-300',
        customStyle
      )}
    >
      {iconLeft && <ReactSVG src={iconLeft} className={iconStyle} />}
      {label}
    </button>
  );
}
