import { ConvertedEventsParticipants, EventObj } from 'ck-queries';

export type EventTableStructure = {
  title:
    | 'TITEL'
    | 'STARTDATUM'
    | 'ORGANISATÖR'
    | 'SKAPAD AV'
    | 'KASSANR'
    | 'KOMMITTÉ'
    | 'DELTAGARE'
    | '';
  dataPath:
    | keyof EventObj
    | 'swishStore'
    | 'committee'
    | keyof ConvertedEventsParticipants;
};

export const tableStructure: EventTableStructure[] = [
  { title: 'TITEL', dataPath: 'nameSwedish' },
  { title: 'STARTDATUM', dataPath: 'startTime' },
  { title: 'SKAPAD AV', dataPath: 'createdBy' },
];

export const swishTableStructure: EventTableStructure[] = [
  { title: 'KASSANR', dataPath: 'swishStore' },
  { title: 'KOMMITTÉ', dataPath: 'committee' },
  { title: 'SKAPAD AV', dataPath: 'createdBy' },
];

export const participantsTableStructure: EventTableStructure[] = [
  { title: 'TITEL', dataPath: 'title' },
  { title: 'STARTDATUM', dataPath: 'startDate' },
  { title: 'ORGANISATÖR', dataPath: 'host' },
  { title: 'DELTAGARE', dataPath: 'participantsQuota' },
];
