interface Props {
  imageUrl: string | undefined;
}

{
  /*
   * The size of images in event-cards exact dimensions are h-[106px] w-[96px]
   * The size of images in event-details exact dimensions are h-[180px] w-[depends on the phone width, used 332px]
   *
   * The preview of the event-detail image has been adjusted to the same height as the event-card image
   * So that both previews will fit on the same line (on most computer screens)
   * height: 180px -> 106px
   * width: 332px -> 192px
   */
}
export const UploadImagePreview = ({ imageUrl }: Props) => {
  if (!imageUrl) return <></>;
  return (
    <div className='flex flex-row flex-wrap items-start justify-around rounded border border-gray-300 bg-gray-100 p-2.5 px-4'>
      <div className='m-2.5 mr-2.5 ml-2.5  flex flex-col items-center'>
        <div className='mb-2.5 text-base font-light text-gray-400'>Listvy</div>
        <div className='flex h-[106px] items-center'>
          <img src={imageUrl} className='h-[106px] w-[96px]  object-cover' />
        </div>
      </div>

      <div className=' m-2.5 ml-2.5 mr-2.5 flex  h-[100%] flex-col items-center'>
        <div className='mb-2.5 text-base font-light text-gray-400'>
          Detaljvy
        </div>
        <div className=' w-[196px] max-w-[196px]'>
          <img src={imageUrl} className='h-[106px] w-full  object-cover' />
          <div className='mt-1.5 text-center text-xs italic text-gray-400'>
            Bilden kan justeras något beroende på skärmstorleken
          </div>
        </div>
      </div>
    </div>
  );
};
