import { APIError, ApiPaths } from 'src/typesGlobal';

/**
 * This is the general fetch function. All API requests should go through this.
 *
 * @param apiSubUrl the path to fetch from
 * @param requestInitConfig optional request config
 * @param disableErrorThrow optional disable for errorthrow
 * @returns a wrapped fetch function with
 */
export async function fetchWithErrorThrowing(
  apiSubUrl: ApiPaths,
  requestInitConfig?: RequestInit,
  disableErrorThrow?: boolean
): Promise<Response> {
  const res = await fetch(`${process.env.REACT_APP_BE_BASEURL}${apiSubUrl}`, {
    ...requestInitConfig,
    credentials: 'include', // Makes sure the auth cookie is attached
  });

  // Return res on status codes 2XX, 3XX
  if (res.ok) {
    return res;
  }

  // Error throwing logic
  let error = {} as APIError;

  // If we can parse the json we know its an APIError
  try {
    const json = await res.json();

    error = new APIError({
      status: json.status,
      title: json.title,
      clientMessageSwedish: json.clientMessageSwedish,
      developerMessage: json.developerMessage,
      errors: json.errors,
      errorType: json.errorType,
    });
  } catch {
    // Custom errors
    switch (res.status) {
      case 404: {
        // Reached server domain, but not a valid route
        if (!disableErrorThrow)
          error = new APIError({
            status: 404,
            title: 'Resursen kunde inte hittas',
            clientMessageSwedish: 'Resursen kunde inte hittas',
            developerMessage: 'Resource not found',
            errorType: '',
          });
        break;
      }
      case 401: {
        // We need to refresh token
        error = new APIError({
          status: 401,
          title: 'Du är inte inloggad',
          clientMessageSwedish: 'Du är inte inloggad',
          developerMessage: 'Unauthorized',
          errorType: 'EXPIRED_TOKEN',
        });
        break;
      }
      default: {
        // If json parsing failed and status is not 404 or 401 => Unknown
        error = new APIError({
          status: res.status,
          title: 'Okänt fel uppstod',
          clientMessageSwedish: 'Något gick fel',
          developerMessage: 'Unknown error occured',
          errorType: 'UNKNOWN',
        });
        break;
      }
    }
  }

  // Throw the relevant error
  throw error;
}
