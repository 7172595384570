import { ThemeDto } from './types';
import dayjs from 'dayjs';
import { standardDateFormat } from 'src/utils';

/**
 * Formats theme data to display in the table
 * @param themes - Theme data to format
 */
export const formatThemeData = (themes: ThemeDto[]) => {
  // Nullcheck
  if (!themes) {
    return [];
  }

  return themes.map((t) => ({
    ...t,
    startFormatted: dayjs(t.start).format(standardDateFormat),
    endFormatted: dayjs(t.end).format(standardDateFormat),
    // ! BACKEND NEEEDS TO PROVIDE THIS IN THE API
    targetGroupString: t.targetGroup
      ? `${t.targetGroup.group} (${t.targetGroup.count} st)`
      : '',
  }));
};
