import { PostPollsRequest } from 'ck-queries';
import dayjs from 'dayjs';
const scheduleTimeDefault = dayjs()
  .add(1, 'week')
  .set('hour', 8)
  .set('minutes', 0)
  .format('YYYY-MM-DD hh:mm');
export const defaultPollValues: Omit<PostPollsRequest, 'targetGroup'> = {
  titleEnglish: '',
  titleSwedish: '',
  questionEnglish: '',
  questionSwedish: '',
  sendPushNotification: 'false',
  answersEnglish: [],
  answersSwedish: [],
  image: null,
  scheduleTime: scheduleTimeDefault,
  isDraft: false,
};
