import {
  FieldErrorsImpl,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';

import React from 'react';
import clsx from 'clsx';

interface FormInputProps<T extends FieldValues>
  extends React.HTMLProps<HTMLInputElement> {
  value?: string;
  register?: UseFormRegister<T>;
  name: Path<T>;
  options?: RegisterOptions;
  errors?: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

export const FormInputGeneric = <T extends FieldValues>({
  register,
  name,
  options = {},
  disabled = false,
  readOnly = false,
  type = 'text',
  ...rest
}: FormInputProps<T>) => {
  const registerInput = register ? register(name, { ...options }) : {};

  return (
    <div className='w-full'>
      <input
        {...registerInput}
        {...rest}
        disabled={disabled}
        className={clsx(
          'h-14 w-full rounded border border-gray-300 px-4',
          disabled &&
            'cursor-not-allowed bg-neutral-50  text-gray-400 placeholder:text-gray-400 hover:border-gray-300 focus:border-gray-300',
          readOnly &&
            'border-none bg-inherit caret-transparent hover:cursor-default focus:border-none focus:outline-none'
        )}
        type={type}
      />

      <div className='h-8 w-full text-sm' />
    </div>
  );
};
