import { useEffect, useState } from 'react';

import { convertFileToUrlString } from './convertFileToUrlString';

// Stores provided image in localStorage and returns the local URL
export const useImagePreview = (image: File | null) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  useEffect(() => {
    async function setPreviewImage() {
      const imageUrl = await convertFileToUrlString(image);
      setImageUrl(imageUrl || undefined);
    }
    setPreviewImage();
  }, [image]);

  return imageUrl;
};
