import { StatusRaw, inviteStatusMap } from './types';

import dayjs from 'dayjs';

export const formatStatus = (
  status: StatusRaw,
  date: string,
  invitedEmail: string | null
) => {
  if (status !== 'AwaitingResponse' && status !== 'Sent') {
    return inviteStatusMap[status];
  }

  const dateObj = dayjs(date);

  // Nullcheck
  if (!dateObj.isValid()) {
    return inviteStatusMap[status];
  }

  if (invitedEmail) {
    return `${
      inviteStatusMap[status]
    } till ${invitedEmail} (utgår ${dateObj.format('YYYY-MM-DD')})`;
  }

  return `${inviteStatusMap[status]} (utgår ${dateObj.format('YYYY-MM-DD')})`;
};
