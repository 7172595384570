import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { ChangeEvent } from 'react';
import { ErrorMessages } from '../errormessages/ErrorMessages';
import { FormLabelNew } from '../FormLabel/FormLabelNew';
import clsx from 'clsx';

type RadioProps<T extends FieldValues> = {
  selectOptions: Array<{ label: string; value: any }>;
  name: Path<T>;
  parentForm: UseFormReturn<T>;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
  valueToDisable?: string;
};

export const RadioButtons = <T extends FieldValues>({
  parentForm,
  disabled,
  selectOptions,
  onChange,
  name,
  label,
  valueToDisable,
}: RadioProps<T>) => {
  const {
    formState: { errors },
  } = parentForm;
  return (
    <div>
      {label && (
        <FormLabelNew label={label} parentForm={parentForm} name={name} />
      )}
      <div className={clsx('flex gap-5 py-2', disabled && 'opacity-25')}>
        {selectOptions.map((option) => (
          <label
            key={`${option.value}`}
            className={clsx(
              'inline-flex',
              !disabled &&
                !(valueToDisable === option.value) &&
                'cursor-pointer'
            )}
          >
            <div
              className={clsx(
                'flex h-6 w-6 shrink-0 rounded-full border-2 border-sky-500 bg-sky-500',
                disabled ||
                  (valueToDisable === option.value && 'border-gray-300')
              )}
            >
              <input
                {...parentForm.register(name, {
                  onChange,
                  required: !disabled
                    ? 'Måste välja ett alternativ'
                    : undefined,
                })}
                type='radio'
                disabled={disabled || valueToDisable === option.value}
                value={option.value}
                className='h-full w-full appearance-none rounded-full border-2 border-white bg-white checked:bg-sky-500 enabled:cursor-pointer'
              />
            </div>
            <span
              className={clsx(
                'ml-2',
                disabled || (valueToDisable === option.value && 'text-gray-400')
              )}
            >
              {option.label}
            </span>
          </label>
        ))}
      </div>
      <ErrorMessages errors={errors} name={name} />
    </div>
  );
};
