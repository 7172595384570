import {
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';

import { ErrorMessages } from './errormessages/ErrorMessages';
import { FormLabelNew } from './FormLabel/FormLabelNew';
import { isValidHexColorCode } from 'src/utils';

interface FormInputProps<T extends FieldValues> {
  label?: string;
  form: UseFormReturn<T>;
  name: Path<T>;
  options?: RegisterOptions;
}

export const ColorPicker = <T extends FieldValues>({
  label,
  name,
  form,
  options = {},
}: FormInputProps<T>) => {
  const {
    setValue,
    register,
    watch,
    formState: { errors },
  } = form;

  return (
    <div>
      {label && <FormLabelNew label={label} name={name} />}
      <div className='mr-6 flex w-fit items-center gap-2 rounded-md border border-gray-200 bg-white px-5 py-2'>
        <div
          className={
            'flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border border-black'
          }
        >
          <input
            onChange={(e) =>
              setValue(name, e.target.value as PathValue<T, Path<T>>)
            }
            type='color'
            value={watch(name)}
            className='-m-5 h-[150%] w-[150%] cursor-pointer'
          />
        </div>
        <input
          {...register(name, {
            ...options,
            validate: (value) =>
              isValidHexColorCode(value) ? true : 'Ogiltig färgkod',
            onChange: (e) =>
              setValue(name, e.target.value as PathValue<T, Path<T>>),
          })}
          type='text'
          id={name}
          value={watch(name)}
          className='w-20 rounded-sm border'
          placeholder='#000000'
          maxLength={7}
        />
      </div>
      <ErrorMessages errors={errors} name={name} />
    </div>
  );
};
