import { AuthorizedFunction, User } from 'ck-queries';

import { PageName } from 'ck-global-types';

// Checks if a user is allowed to access a page based on their functions
export const isPageAllowed = (user: User, demandedPage: PageName) => {
  // Check if demanded page is always allowed
  if (alwaysAllowedPages.some((regex) => regex.test(demandedPage))) {
    return true;
  }

  // Check if demanded route is allowed within any of the functions provided
  return user.functions.some((functionName) => {
    // List of all permitted regex paths for the function
    const regexList = functionToRouteRegexMap[functionName];

    // Check if functionName matches any of the regex
    return regexList?.some((regex) => regex.test(demandedPage));
  });
};

// List of all pages that are allowed for all users
const alwaysAllowedPages: Array<RegExp> = [
  /^\/$/,
  /^\/settings$/,
  /^\/login$/,
  /^\/division\/invite\/\S+$/, // Matches any invite token
  /^\/committee\/invite\/\S+$/, // Matches any invite token
];

// TODO: Refactor to have simple strings for everything that isn't dynamic. Let consumer check if it is a string or regex.
// Mapping of all AuthorizedFunctions to their corresponding pages expressed as regex
const functionToRouteRegexMap: Record<AuthorizedFunction, RegExp[]> = {
  PushNotification: [
    /^\/push-notifications\/create$/,
    /^\/push-notifications\/archive$/,
    /^\/push-notifications\/copy\/.*$/,
    /^\/push-notifications\/edit\/.*$/,
  ],
  Committee: [/^\/organisations\/\S+$/],
  CustomTheme: [
    /^\/themes\/create$/,
    /^\/themes\/archive$/,
    /^\/themes\/copy\/.*$/,
    /^\/themes\/edit\/.*$/,
  ],
  Events: [
    /^\/events\/edit\/.*$/,
    /^\/events\/copy\/.*$/,
    /^\/events\/create$/,
    /^\/events\/archive$/,
    /^\/events\/swish$/,
  ],
  Polls: [
    /^\/polls\/create$/,
    /^\/polls\/edit\/.*$/,
    /^\/polls\/copy\/.*$/,
    /^\/polls\/archive$/,
  ],
  InviteDivisionAdmin: [
    /^\/organisations$/,
    /^\/organisations\/\S+$/, // Matches any division ID
  ],
};
