import { Dispatch, SetStateAction } from 'react';
import {
  FormTextInput,
  LoadingSpinner,
  MultiSelectDropdown,
  RadioButtons,
  SingleSelectDropdown,
  UploadInput,
} from 'ck-components';
import {
  PostCreateEventV1,
  heldInLanguage,
  targetUserType,
  targetUserTypeEnum,
  targetUserTypeMap,
  useAuth,
  useEventsCategories,
  useEventsDivisions,
  useEventsHosts,
  useUser,
} from 'ck-queries';
import { dayjsTime, useImagePreview } from 'src/utils';

import { UploadImagePreview } from 'src/components/Form/UploadImagePreview';
import { UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import { useEventsUserTypes } from 'src/queries/events/useEventsUserTypes';
import { useUserSpecificEventsDivisions } from 'src/queries/events/useEventsUserSpecificDivisions';

interface Props {
  form: UseFormReturn<PostCreateEventV1>;
  isEdit?: boolean;
  setSingelSelectDivision: Dispatch<SetStateAction<string>>;
  singelSelectDivision: string;
}

/**
 * Sub-section of the Create Event form for organisation information
 *
 * @param form React Hook Form object for the Create Event form
 * @returns Section of the Create Event form for organisation information
 */

export function OrganisationSection({
  form,
  isEdit = false,
  setSingelSelectDivision,
  singelSelectDivision,
}: Props) {
  const { watch, trigger, setValue } = form;
  const date = watch('date');
  const image = watch('image');
  const imageUrl = useImagePreview(image);

  // TODO: Add error handling for queries
  const Q_eventsDivisions = useEventsDivisions();
  const Q_userSPecificEventsDivisions = useUserSpecificEventsDivisions();
  const Q_hosts = useEventsHosts(false);
  const Q_categories = useEventsCategories();
  const Q_user = useUser();
  const Q_userTypes = useEventsUserTypes();

  const { user } = useAuth();
  const heldInLanguageOptions: Array<{ label: string; value: heldInLanguage }> =
    [
      { label: 'Engelska', value: 'English' },
      { label: 'Svenska', value: 'Swedish' },
      { label: 'Inte applicerbart', value: 'Not applicable' },
    ];

  if (
    Q_categories.isLoading ||
    Q_hosts.isLoading ||
    !Q_hosts.data ||
    Q_eventsDivisions.isLoading ||
    Q_userTypes.isLoading ||
    Q_user.isLoading
  ) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingSpinner width={100} />
      </div>
    );
  }

  const isCommitteeModerator = user?.roles.includes('CommitteeModerator');

  // This feels stupid. There has to be a better thing to do. But I do not have time I have a vacation to go to and a deadline to reach ¯\_(ツ)_/¯ // Sabine 11/7-24
  const userTypeSelectOptions: Array<{
    label: string;
    value: targetUserType;
  }> = [
    { label: targetUserTypeMap['Students'], value: 'Students' },
    {
      label: targetUserTypeMap['StudentsAtDivision'],
      value: 'StudentsAtDivision',
    },
    { label: targetUserTypeMap['All'], value: 'All' },
    { label: targetUserTypeMap['Alumnus'], value: 'Alumnus' },
  ];

  return (
    <>
      <div className='flex gap-14'>
        <div className='flex-1'>
          {Q_hosts.data && (
            <SingleSelectDropdown
              label='Organisatör / Host'
              description={
                !isCommitteeModerator
                  ? `Kommitté som annordnar. Skapa en kommité under "Organisationer" om listan är tom.`
                  : ''
              }
              name='host'
              parentForm={form}
              optionsData={Q_hosts.data.map((host) => host.name)}
              disabled={
                Q_hosts.data?.length === 0 ||
                (isEdit &&
                  form.getValues().eventReservationDetails.reservationType ===
                    'Paid')
              }
            />
          )}
          {Q_categories.data && (
            <MultiSelectDropdown
              name='categories'
              parentForm={form}
              optionsData={Q_categories.data.map(({ name }) => name)}
              label='Eventkategori / Event category'
              description='Välj en eller flera kategorier'
            />
          )}
          <FormTextInput
            label={'Datum'}
            parentForm={form}
            name='date'
            minDate={dayjs().format('YYYY-MM-DD')}
            type='date'
            options={{
              onBlur: () => trigger('startTime'),
            }}
          />
          <div className='flex gap-6'>
            {/* TODO: Refactor to an independent FormDateInput component */}

            <FormTextInput
              label={'Starttid'}
              parentForm={form}
              name='startTime'
              type='time'
              options={{
                validate: {
                  isInFuture: (newStartTime: string) =>
                    dayjs(date)
                      .set('h', Number(newStartTime.split(':')[0]))
                      .set('m', Number(newStartTime.split(':')[1]))
                      .isAfter(dayjs()) || 'Start måste vara i framtiden.',
                },
                onChange: ({ target: { value } }) =>
                  // Set endTime to startTime + 1 hour
                  setValue(
                    'endTime',
                    dayjsTime(value).add(1, 'hour').format('HH:mm')
                  ),
                onBlur: () => {
                  trigger('startTime');
                  trigger('endTime');
                },
              }}
            />
            <FormTextInput
              label={'Sluttid'}
              parentForm={form}
              name='endTime'
              type='time'
            />
          </div>
          <RadioButtons
            label='Eventets språk / Event language'
            name='heldInLanguage'
            parentForm={form}
            selectOptions={heldInLanguageOptions}
          />
        </div>
        <div className='flex-1'>
          <UploadInput
            parentForm={form}
            description='Bilden ska vara minst 500 px bred. Undvik att ladda upp logotyper, bilden beskärs automatiskt.'
            label='Foto / Image (valfri)'
            name='image'
            accept='.jpeg, .jpg, .png'
            emptyState='Ladda upp bild / Upload image'
          />
          {image && image?.size && (
            <FormTextInput
              label='Photo cred'
              name='imageCred'
              parentForm={form}
              maxLength={40}
            />
          )}
          <UploadImagePreview imageUrl={imageUrl} />
        </div>
      </div>
      <div>
        <RadioButtons
          label='Visas För'
          name='targetUserType'
          parentForm={form}
          selectOptions={userTypeSelectOptions}
          valueToDisable={
            !Q_userSPecificEventsDivisions.data ||
            Q_userSPecificEventsDivisions.data.length < 1
              ? targetUserTypeEnum.StudentsAtDivision
              : undefined
          }
        />
        <div className='flex'>
          <div className='flex-1'>
            {form.getValues().targetUserType ===
              targetUserTypeEnum.StudentsAtDivision && (
              <SingleSelectDropdown
                name='divisions'
                parentForm={form}
                value={singelSelectDivision}
                onChange={(val) => {
                  setSingelSelectDivision(val);
                  form.setValue('divisions', [val]);
                }}
                optionsData={Q_userSPecificEventsDivisions.data ?? []}
                useOtherValues={Q_userSPecificEventsDivisions.data}
                disabled={
                  !Q_userSPecificEventsDivisions.data ||
                  Q_userSPecificEventsDivisions.data?.length < 1
                }
                label='Sektion / Division'
              />
            )}
            {form.getValues().targetUserType !==
              targetUserTypeEnum.StudentsAtDivision && (
              <MultiSelectDropdown
                name='divisions'
                parentForm={form}
                optionsData={Q_eventsDivisions.data ?? []}
                label='Sektioner / Divisions'
                description='Om inget är valt väljs Alla sektioner'
              />
            )}
          </div>
          <div className='flex-1' />
        </div>
      </div>
    </>
  );
}
