import { FieldErrorsImpl, MultipleFieldErrors } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';

interface FormErrorMessageProps {
  name: string;
  takeUpSpace?: boolean;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

/**
 * This components shows and returns the error message(s) for a given input field.
 * It's been extracted to its own component so it can be resued across all different types
 * of input fields.
 *
 * @param name the name of the input field we are showing errors for
 * @param errors the errors object as returned from react-hook-form
 */

export function ErrorMessages({
  name,
  takeUpSpace = true,
  errors = {},
}: FormErrorMessageProps) {
  const renderErrorMessages = (
    message: string,
    messages: MultipleFieldErrors | undefined
  ) => {
    return (
      <>
        {message && <p className='mt-1 text-sm text-rose-600'>{message}</p>}
        {messages &&
          Object.entries(messages).map(([type, message]) => (
            <p key={type} className='mt-1 text-sm text-rose-600'>
              {message}
            </p>
          ))}
      </>
    );
  };

  return (
    <div
      className={clsx(
        'w-full text-sm',
        takeUpSpace && 'h-8 border border-transparent'
      )}
    >
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message, messages }) =>
          renderErrorMessages(message, messages)
        }
      />
    </div>
  );
}
