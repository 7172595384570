import {
  ButtonTypeSelect,
  ThemeDto,
  ThemePrefill,
  ThemeStatus,
  ThemeSubset,
} from 'ck-queries';

import dayjs from 'dayjs';
import { fetchImageFromRemoteBlobStorage } from './fetchImageFromBlobStorage';
import { removeNullUndefinedProperties } from './removeNullUndefinedProperties';

export const formatThemePrefill = async (
  rawPrefill: ThemeDto | undefined,
  isCopy: boolean,
  themeId: string | undefined
) => {
  if (!rawPrefill) return Promise.resolve({ prefill: undefined });
  // Remove null and undefined properties
  const prefill = removeNullUndefinedProperties(rawPrefill);
  // Fetch image from blob storage
  const image = await fetchImageFromRemoteBlobStorage(rawPrefill.pngUrl);

  const title = isCopy ? `Kopia av ${prefill.title}` : prefill.title;
  const start = dayjs(prefill.start).format('YYYY-MM-DDTHH:mm');
  const end = dayjs(prefill.end).format('YYYY-MM-DDTHH:mm');

  const countDownStart = prefill.countdownStartTime
    ? dayjs(prefill.countdownStartTime).format('YYYY-MM-DDTHH:mm')
    : undefined;

  const theme: ThemeSubset = JSON.parse(prefill.theme);

  const countdownLinkUrl = prefill.countdownLinkUrl
    ? prefill.countdownLinkUrl
    : '';
  const themeLinkUrl = prefill.themeLinkUrl ? prefill.themeLinkUrl : '';

  let buttonType: ButtonTypeSelect;
  if (prefill.themeLinkUrl) {
    buttonType = 'url';
  } else if (prefill.themeButtonEventId) {
    buttonType = 'in-app';
  } else {
    buttonType = 'none';
  }

  let countdownButtonType: ButtonTypeSelect;
  let countDownEnabled: 'enabled' | 'disabled' = 'enabled';
  if (prefill.countdownLinkUrl) {
    countdownButtonType = 'url';
  } else if (prefill.countdownButtonEventId) {
    countdownButtonType = 'in-app';
  } else {
    countdownButtonType = 'none';
  }

  if (!countDownStart) {
    countDownEnabled = 'disabled';
  }

  const formattedPrefill: ThemePrefill = {
    userName: '',
    image: image,
    countdownButtonEventId: prefill.countdownButtonEventId,
    themeButtonEventId: prefill.themeButtonEventId,
    countdownEnabled: countDownEnabled,
    title: title,
    themeLinkUrl: themeLinkUrl,
    themeLinkButtonText: prefill.themeLinkButtonText,
    countdownLinkUrl: countdownLinkUrl,
    start: start,
    end: end,
    countdownButtonType: countdownButtonType,
    themeLinkButtonType: buttonType,
    status: prefill.status as ThemeStatus,
    theme: theme,
    targetGroup: prefill.targetGroup,
    countdownStartTime: countDownStart,
    // At this point we can be sure id is a string since the prefill exists
    id: themeId as string,
  };

  return { prefill: formattedPrefill };
};
