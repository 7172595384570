import clsx from 'clsx';

interface FieldLabelProps {
  children: string;
  customStyle?: string;
}

/**
 * This component displays text in the form of a field label.
 * The same styling is used for the input field labels in the custom form component.
 *
 * @param chilren the text to be rendered
 * @param customStyle optional custom styling
 */
export function FieldLabel({ children, customStyle }: FieldLabelProps) {
  return (
    <span
      className={clsx(
        'tracking-formLabelText text-formLabelText text-left text-base font-light uppercase',
        customStyle
      )}
    >
      {children}
    </span>
  );
}
