/**
 * Lightens a given hex color by the specified amount.
 * @param hex The hex color code to lighten.
 * @param amount The amount to lighten the color (default: 0.2).
 * @returns The lightened hex color code.
 */
export function lightenColor(hex: string, amount = 0.2): string {
  // Check if the input is a valid hex code
  if (!/^#[0-9a-fA-F]{6}$/.test(hex)) {
    console.warn(`Invalid hex code: ${hex}`);
    return hex;
  }

  // Convert hex to RGB
  const rgb = {
    r: parseInt(hex.substring(1, 3), 16),
    g: parseInt(hex.substring(3, 5), 16),
    b: parseInt(hex.substring(5, 7), 16),
  };

  // Lighten each RGB component
  const lightenedRgb = {
    r: Math.min(Math.round(rgb.r + 255 * amount), 255),
    g: Math.min(Math.round(rgb.g + 255 * amount), 255),
    b: Math.min(Math.round(rgb.b + 255 * amount), 255),
  };

  // Convert RGB back to hex
  const lightenedHex = `#${[
    lightenedRgb.r.toString(16).padStart(2, '0'),
    lightenedRgb.g.toString(16).padStart(2, '0'),
    lightenedRgb.b.toString(16).padStart(2, '0'),
  ].join('')}`;

  return lightenedHex;
}
