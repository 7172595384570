import { CancelButton, ConfirmButton } from 'ck-components';

import clsx from 'clsx';

interface FormProps {
  children?: React.ReactNode;
  onSubmit: () => void;
  submitButtonLabel: string;
  customFormStyle?: string;
  submitButtonStyle?: string;
  buttonType?: 'button' | 'submit';
  explicitDisabled?: boolean;
  trigger?: () => Promise<boolean>;
  centerButtons?: boolean;
  isSubmitting?: boolean;
  showCancelButton?: boolean;
  onCancel?: () => void;
  cancelButtonStyle?: string;
  cancelButtonLabel?: string;

  onCleanForm?: () => void;

  onSaveDraft?: () => void;
  ignoreGap?: boolean;
}

/**
 * The root object of our form wrapper with custom styling. Only enables the submit button if the
 * form in its entirety is valid.
 *
 * @param children the child objects to be rendered (FormLabels and FormInputs)
 * @param onSubmit the submit function which should be wrapped in the handleSubmit from react-hook-form
 * @param submitButtonLabel the text for the submit button
 * @param customFormStyle optional styling
 * @param isValid isValid returned from react-hook-form.
 * @param submitButtonStyle optional styling for the submit button
 * @param showCancelButton denotes whether or not the cancel button should be rendered for the form
 * @param onCancel callback function for the cancel button
 * @param cancelButtonStyle optional styling for the cancel button
 * @param cancelButtonLabel the text for the cancel button
 * @param buttonType set to button to prevent default onSubmit behavior like a page refresh
 * @param explicitDisabled set to true to explicitly disable the submit button
 * @param centerButtons set to true to center the buttons
 * @param trigger the trigger function from react-hook-form
 * @param isSubmitting set to true to show a loading spinner on the submit button
 * @param ignoreGap optional prop to ignore the gap, used in modals
 */

export const Form = ({
  children,
  onSubmit,
  onCancel = () => undefined,
  customFormStyle,
  submitButtonLabel,
  submitButtonStyle,
  cancelButtonStyle,
  showCancelButton,
  cancelButtonLabel = 'Avbryt',
  buttonType = 'submit',
  explicitDisabled,
  centerButtons,
  trigger,
  isSubmitting,
  ignoreGap = false,
  onCleanForm,
  onSaveDraft,
}: FormProps) => {
  // TODO: The useFormReturn could be propped down instead of trigger
  // TODO: The isSubmitting is always needed. Prop down the entire mutation instead and make it required.
  // TODO: showCancelButton could be derived from if onCancel is defined or not
  // TODO: Buttons customStyle should have a better default
  // TODO: customFormStyle should be called className instead to get tailwind intellisense
  // TODO: Is explicitDisable really needed? If it is submitting you should never be able to click the button
  // TODO: Figure out if something else can be derived from the useFormReturn prop.
  // TODO: Simplify props types

  return (
    <form
      onSubmit={onSubmit}
      className={clsx(
        'flex w-full flex-col',
        !ignoreGap && 'gap-7',
        customFormStyle
      )}
    >
      {children}
      <div
        className={clsx(
          'flex flex-row gap-7',
          centerButtons && 'justify-center'
        )}
      >
        {/* Used by multiple components to cancle a procedure */}
        {showCancelButton && (
          <CancelButton
            label={cancelButtonLabel}
            onClick={onCancel}
            className={cancelButtonStyle}
          />
        )}

        {/* Clear a form */}
        {onCleanForm && (
          <CancelButton label={'Rensa formuläret'} onClick={onCleanForm} />
        )}
        {/* Saves a form as a draft */}
        {onSaveDraft && (
          <CancelButton label={'Spara utkast'} onClick={onSaveDraft} />
        )}

        {/* Submit form */}
        <ConfirmButton
          type={buttonType}
          className={submitButtonStyle}
          label={submitButtonLabel}
          isLoading={isSubmitting}
          onClick={async () => {
            // If no trigger is provided, just submit the form
            if (!trigger) return onSubmit();

            // Trigger all fields to validate
            const result = await trigger();

            // If trigger returned true (no errors), submit the form
            if (result) {
              return onSubmit();
            }
          }}
          disabled={explicitDisabled === true || isSubmitting}
        />
      </div>
    </form>
  );
};
