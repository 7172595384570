import { ChevronRight } from 'src/assets';
import { PageName } from 'src/typesGlobal';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

interface Props {
  pathList: Array<[string, PageName?]>;
}

/**
 * A component that displays a list of links to pages in the form of a breadcrumb.
 * @param pathList An array of tuples where the first element is the name of the page and the second is the page route.
 * @returns A component that displays a list of links to pages in the form of a breadcrumb.
 */
export const BreadCrumbs = ({ pathList }: Props) => {
  const navigate = useNavigate();

  return (
    <div className='flex h-6 items-center gap-2'>
      {pathList.map((el, idx) => {
        const isLast = idx + 1 === pathList.length;
        const name = el[0];
        const page = el[1];

        return (
          <div key={`${name}-${idx}`} className='flex items-center gap-1'>
            <div
              className={clsx(
                'font-light text-gray-800',
                page && 'cursor-pointer',
                isLast && 'font-bold text-sky-400'
              )}
              onClick={() => page && navigate(page)}
            >
              {name}
            </div>
            {!isLast && <ReactSVG src={ChevronRight} className='h-4 w-4' />}
          </div>
        );
      })}
    </div>
  );
};
