import { Copy, Download, Edit, Trash } from 'src/assets';

import { OurTooltip } from '../OurTooltip/OurTooltip';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

interface RowComponentProps<T> {
  dataPaths: Array<keyof T>;
  data: { [key in keyof T]: any };
  onRowPress?: (data: any) => void;
  isClickable: boolean;
  onDeletePress?: (data: any) => void;
  onEditPress?: (data: any) => void;
  onCopyPress?: (data: any) => void;
  onDownloadPress?: (data: any) => void;
  onCustomPress?: (data: any) => void;
  customLastColumn?: React.ReactNode;
}

/**
 * The row component which is rendered within the table.
 *
 * @param dataPaths an array of strings containing the paths which the data will be mapped against
 * @param data the data which will be rendered on the current row
 * @param onRowPress a callback executed whenever the current row is pressed
 * @param isClickable denotes whether or not the cursor styling should be applied
 */

export default function RowComponent<T extends { [key: string]: any }>({
  dataPaths,
  data,
  onRowPress = () => undefined,
  isClickable,
  onDeletePress,
  onEditPress,
  onCopyPress,
  onCustomPress,
  onDownloadPress,
  customLastColumn,
}: RowComponentProps<T>) {
  const hasFunctions = onDeletePress || onEditPress || onCopyPress;

  return (
    <tr>
      {dataPaths.map((path, index) => (
        <td
          key={`${data[path]}-${index}`}
          className={clsx(
            'h-16 overflow-hidden text-ellipsis whitespace-nowrap border-t border-b bg-white px-4 py-4 font-light first:rounded-l-2xl first:border-l first:pl-7 last:rounded-r-2xl last:border-r last:pr-7',
            isClickable && 'hover:cursor-pointer'
          )}
          onClick={() => onRowPress(data)}
        >
          {data[path]}
        </td>
      ))}
      {hasFunctions && (
        <td
          key={'functions'}
          className={
            'flex h-16 shrink flex-row items-center justify-end gap-2 place-self-end overflow-hidden text-ellipsis whitespace-nowrap border-t border-b bg-white px-4 py-4 font-light first:border-l last:rounded-r-2xl last:border-r last:pr-7'
          }
        >
          {onCopyPress && (
            <OurTooltip content='kopiera' id='copy-row'>
              <button
                aria-label={'copyRowButton'}
                onClick={() => onCopyPress(data)}
              >
                <ReactSVG src={Copy} className='w-5 cursor-pointer' />
              </button>
            </OurTooltip>
          )}
          {onEditPress && (
            <OurTooltip content='redigera' id='edit-row'>
              <button
                aria-label={'editRowButton'}
                onClick={() => onEditPress(data)}
              >
                <ReactSVG src={Edit} className='w-5 cursor-pointer' />
              </button>
            </OurTooltip>
          )}
          {onDownloadPress && (
            <OurTooltip content='ladda ner' id='download-row'>
              <button
                aria-label={'downloadRowButton'}
                onClick={() => onDownloadPress(data)}
              >
                <ReactSVG
                  title='Download button'
                  src={Download}
                  className='w-5 cursor-pointer'
                />
              </button>
            </OurTooltip>
          )}
          {onDeletePress && (
            <OurTooltip content='radera' id='delete-row'>
              <button
                aria-label={'deleteRowButton'}
                onClick={() => onDeletePress(data)}
              >
                <ReactSVG
                  title='Delete button'
                  src={Trash}
                  className='w-5 cursor-pointer'
                />
              </button>
            </OurTooltip>
          )}
        </td>
      )}
      {customLastColumn && (
        <td
          onClick={onCustomPress ? () => onCustomPress(data) : undefined}
          className={
            'flex h-16 flex-row items-center justify-end gap-2 place-self-end text-ellipsis whitespace-nowrap border-t border-b bg-white px-4 py-4 font-light first:border-l last:rounded-r-2xl last:border-r last:pr-7'
          }
        >
          {customLastColumn}
        </td>
      )}
    </tr>
  );
}
