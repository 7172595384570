import { QK } from '../queryKeys';
import { fetchWithErrorThrowing } from '../../utils/fetchWithErrorThrowing';
import { useQuery } from '@tanstack/react-query';

export const useEventsHosts = (excludeSwishStoreConnected: boolean) => {
  return useQuery({
    queryKey: [QK.events.hosts, excludeSwishStoreConnected],
    queryFn: () => getEventsHosts(excludeSwishStoreConnected),
  });
};

async function getEventsHosts(
  excludeSwishStoreConnected: boolean
): Promise<{ name: string; id: string }[]> {
  const res = await fetchWithErrorThrowing(
    `/events/hosts?excludeSwishStoreConnected=${excludeSwishStoreConnected}`,
    {
      method: 'GET',
    }
  );

  const data: { hosts: { name: string; id: string }[] } = await res.json();

  // Sort hosts alphabetically by name
  data.hosts.sort((a, b) => a.name.localeCompare(b.name));

  return data.hosts;
}
