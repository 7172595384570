import { PhoneMockBase } from 'ck-components';
import { PhoneMockConfig } from 'ck-queries';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { useEffect } from 'react';

interface Props extends PhoneMockConfig {
  index: number;
  setSelectedIndex: (index: number) => void;
}

export const PhoneMockWithToggle = (props: Props) => {
  const { hasCountdown, index, setSelectedIndex } = props;

  // If the user has disabled the countdown, we want to show the theme tab
  useEffect(() => {
    if (!hasCountdown) {
      setSelectedIndex(0);
    }
  }, [hasCountdown, setSelectedIndex]);

  return (
    <div className='sticky top-0 flex h-fit flex-col gap-5'>
      <Tab.Group selectedIndex={index} onChange={setSelectedIndex}>
        <Tab.List
          className={clsx(
            'mt-6 mb-2 flex h-14 items-center gap-7 outline-none',
            !hasCountdown && 'opacity-0'
          )}
        >
          <div className='rounded-xl bg-slate-300 p-1'>
            <Tab>
              {({ selected }) => (
                <TabButton
                  title='Tema'
                  selected={selected}
                  hasCountdown={hasCountdown}
                />
              )}
            </Tab>
            <Tab>
              {({ selected }) => (
                <TabButton
                  title='Countdown'
                  selected={selected}
                  hasCountdown={hasCountdown}
                />
              )}
            </Tab>
          </div>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className='outline-none'>
            <PhoneMockBase {...props} />
          </Tab.Panel>
          <Tab.Panel className='outline-none'>
            <PhoneMockBase {...props} isCountdown />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

// NOTE: Extract this to a separate component if you need to use it elsewhere
const TabButton = ({
  selected,
  title,
  hasCountdown,
}: {
  title: string;
  selected: boolean;
  hasCountdown: boolean;
}) => (
  <>
    {hasCountdown && (
      <div
        role='button'
        className={clsx(
          'w-40 rounded-lg px-4 py-2 outline-none',
          selected && 'bg-white'
        )}
      >
        {title}
      </div>
    )}
  </>
);
