import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';

import { User } from './types';
import { localStorageTyped } from 'src/utils';

// Define AuthContext type
interface AuthContext {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
}

// Create context
export const AuthContext = createContext<AuthContext | undefined>(undefined);

// Abstraction that will wrap the children which should have access to the context
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  // Initialize state with user from localStorage to persist user over page refresh
  const [user, setUser] = useState<User | null>(
    () => localStorageTyped.get('USER') ?? null
  );

  // Update user in localStorage when user changes
  useEffect(() => {
    if (user) {
      localStorageTyped.set('USER', user);
    }
  }, [user]);

  // Wrap children and initialize the getter/setter of user in the context value
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
