import { QK } from '../queryKeys';
import { formatNotificationPrefill } from 'src/utils/formatNotificationPrefill';
import { useNotificationsDraft } from './useNotificationsDraft';
import { useNotificationsHistory } from './useNotificationsHistory';
import { useNotificationsScheduled } from './useNotificationsScheduled';
import { useQuery } from '@tanstack/react-query';

export const useNotificationPrefill = (
  notificationId: string | undefined,
  isCopy: boolean
) => {
  const { data: historyNotifications } = useNotificationsHistory();
  const { data: draftNotifications } = useNotificationsDraft();
  const { data: scheduledNotifications } = useNotificationsScheduled();

  const notifications = [
    ...(historyNotifications ?? []),
    ...(draftNotifications ?? []),
    ...(scheduledNotifications ?? []),
  ];

  return useQuery({
    queryKey: [...QK.notifications.prefill, notificationId, isCopy],
    queryFn: async () => {
      const maybeNotification = notifications.find(
        ({ id }) => id === notificationId
      );
      return formatNotificationPrefill(maybeNotification, isCopy);
    },
    enabled: true,
  });
};
