import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GK } from '../queryKeys';
import { fetchWithErrorThrowing } from 'ck-utils';
import { toast } from 'react-hot-toast';

export const useThemeDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (themeId: string) =>
      toast.promise(
        deleteTheme(themeId),
        {
          loading: 'Laddar...',
          success: 'Temat är raderat',
          error: 'Kunde inte radera temat',
        },
        {
          duration: 8000,
        }
      ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: GK.themes });
    },
  });
};

export async function deleteTheme(themeId: string) {
  return fetchWithErrorThrowing(`/themes/${themeId}`, {
    method: 'DELETE',
  });
}
