import { PostPushNotification } from 'ck-queries';
import dayjs from 'dayjs';
const scheduleTimeDefault = dayjs().add(1, 'hour').format('YYYY-MM-DD hh:mm');
export const defaultPushNotificationValues: Omit<
  PostPushNotification,
  'targetGroup'
> = {
  inboxMessageEnglish: '',
  inboxMessageSwedish: '',
  titleEnglish: '',
  titleSwedish: '',
  notificationMessageEnglish: '',
  notificationMessageSwedish: '',
  scheduleTime: scheduleTimeDefault,
  isDraft: false,
};
