import {
  FormLabel,
  FormMultiLineTextInput,
  FormTextInput,
  SubTitle,
  ToggleRadioButtons,
} from 'ck-components';

import { PostCreateEventV1 } from 'ck-queries';
import { UseFormReturn } from 'react-hook-form';
import { useState } from 'react';

type UrlStateOptions = 'hideUrlFields' | 'showUrlFields';

interface Props {
  form: UseFormReturn<PostCreateEventV1>;
}

/**
 * Sub-section of the Create Event form for general information
 *
 * @param form React Hook Form object for the Create Event form
 * @returns Section of the Create Event form for general information
 */

export function GeneralSection({ form }: Props) {
  const { watch } = form;
  const eventLink = watch('eventLink');

  // Local state for conditionally rendering fields
  const [urlFieldsState, setUrlFieldsState] = useState<UrlStateOptions>(
    eventLink ? 'showUrlFields' : 'hideUrlFields'
  );

  const urlSelectOptions: Array<{ label: string; value: UrlStateOptions }> = [
    { label: 'Ingen knapp', value: 'hideUrlFields' },
    { label: 'URL', value: 'showUrlFields' },
  ];

  return (
    <>
      <div className='flex gap-14'>
        <div className='flex-1'>
          <SubTitle label='Svenska' />
          <FormTextInput
            label='Eventnamn'
            name='nameSwedish'
            parentForm={form}
            maxLength={40}
          />
          <FormMultiLineTextInput
            label='Beskrivning'
            name='descriptionSwedish'
            parentForm={form}
            maxLength={3000}
          />
          <FormTextInput
            label={'Plats för eventet'}
            name='locationSwedish'
            parentForm={form}
            maxLength={40}
          />
        </div>
        <div className='flex-1'>
          <SubTitle label='English' />
          <FormTextInput
            label='Event name'
            name='name'
            parentForm={form}
            maxLength={40}
          />
          <FormMultiLineTextInput
            label='Description'
            name='description'
            parentForm={form}
            maxLength={3000}
          />
          <FormTextInput
            label='Event location'
            name='location'
            parentForm={form}
            maxLength={40}
          />
        </div>
      </div>

      <div className='mb-10'>
        <FormLabel
          label={'KNAPP MED LÄNK (VALFRI) / BUTTON WITH LINK (OPTIONAL)'}
        />
        <ToggleRadioButtons
          selectedValue={urlFieldsState}
          selectOptions={urlSelectOptions}
          onChange={(e) => setUrlFieldsState(e.target.value as UrlStateOptions)}
        />
      </div>
      {urlFieldsState === 'showUrlFields' && (
        <div className='flex gap-14'>
          <div className='flex-1'>
            <FormTextInput
              label='Länk'
              description='Facebook, hemsida etc. Skriv länken med https:// eller www.'
              name='eventLink'
              parentForm={form}
            />
            <FormTextInput
              label='Knapptext'
              name='linkButtonTextSwedish'
              parentForm={form}
              maxLength={18}
            />
          </div>
          <div className='flex flex-1 items-end'>
            <FormTextInput
              label='Button text'
              name='linkButtonText'
              parentForm={form}
              maxLength={18}
            />
          </div>
        </div>
      )}
    </>
  );
}
