import { Link, useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';

import { PageName } from 'src/typesGlobal';
import { RegisterSubLinkContext } from './NavLink';
import { checkUrlMatch } from 'src/utils';
import clsx from 'clsx';

interface SubNavLinkProps {
  href: PageName;
  label: string;
}

/**
 * This is a clickable link for a subpage in the navigation bar.
 * The text color turns blue when clicked on.
 *
 * @param href the url to the page we want to redirect to
 * @param label the clickable text
 */

export function SubNavLink({ href, label }: SubNavLinkProps) {
  const { registerNewSubLink } = useContext(RegisterSubLinkContext);

  const location = useLocation();

  useEffect(() => {
    registerNewSubLink(href);
  }, [href, registerNewSubLink]);

  return (
    <Link
      to={href}
      className={clsx(
        'text-base font-light',
        checkUrlMatch(href, location.pathname) ? 'text-sky-500' : 'text-black'
      )}
    >
      {label}
    </Link>
  );
}
