import { EditInfo, LoadingSpinner, MainTitle } from 'ck-components';
import { EventCreateForm, EventsCreateInfoModal } from 'src/widgets';
import { Navigate, useParams } from 'react-router-dom';

import { useEventsPrefill } from 'ck-queries';
import { useState } from 'react';

interface Props {
  isEdit?: boolean;
  isCopy?: boolean;
}

export function EventCreatePage({ isEdit, isCopy }: Props) {
  // Get the id from the route when editing an event
  const { id } = useParams();
  const { data, isLoading } = useEventsPrefill(id, Boolean(isCopy));
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const prefill = data?.prefill;

  if (!data && id && !isLoading) {
    // If there is no copy/edit data just go to normal form
    return <Navigate to='/events/create' replace={true} />;
  }

  if (isLoading) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingSpinner width={100} />
      </div>
    );
  }

  return (
    <div className='flex flex-1 flex-col'>
      <EventsCreateInfoModal
        isOpen={infoModalOpen}
        setIsOpen={setInfoModalOpen}
      />
      {prefill && <EditInfo isDraft={isEdit && prefill.isDraft} />}
      <MainTitle
        label={
          isEdit
            ? 'Redigera event'
            : isCopy && prefill?.nameSwedish
            ? prefill.nameSwedish
            : 'Skapa event'
        }
        customStyle='pb-8'
        infoIcon
        onClickInfoIcon={() => setInfoModalOpen(true)}
      />

      <EventCreateForm prefill={prefill} isEdit={isEdit} isCopy={isCopy} />
    </div>
  );
}
