import { useCallback, useReducer } from 'react';

enum ActionTypes {
  SET_MAX_LENGTH = 'SET_MAX_LENGTH',
  UPDATE_CURRENT_INPUT_LENGTH = 'UPDATE_CURRENT_INPUT_LENGTH',
}

interface FormLabelState {
  hasMaxLength: boolean;
  maxLength: number;
  currentInputLength: number;
}

interface FormLabelReducerAction {
  type: string;
  payload?: any;
}

/**
 * This reducer keeps track of the local state for all child components to a form label.
 * It could not be stored in redux since we need one state manager per input field.
 * As of now it provides us with functionality to keep track of a max length on an input field, and show the length of the current input.
 *
 * It can be expanded in the future to also contain logic for other custom form input options, should we need it.
 */

export default function useFormLabelReducer() {
  const initialState: FormLabelState = {
    hasMaxLength: false,
    maxLength: 0,
    currentInputLength: 0,
  };

  const onSetMaxLength = (store: FormLabelState, payload: any) => {
    const { maxLength } = payload;

    return {
      ...store,
      maxLength: maxLength,
      hasMaxLength: true,
    };
  };

  const onUpdateInputLength = (store: FormLabelState, payload: any) => {
    const { inputLength } = payload;

    return {
      ...store,
      currentInputLength: inputLength,
    };
  };

  const reducer = (store: FormLabelState, action: FormLabelReducerAction) => {
    const { type, payload } = action;

    switch (type) {
      case ActionTypes.SET_MAX_LENGTH:
        return onSetMaxLength(store, payload);
      case ActionTypes.UPDATE_CURRENT_INPUT_LENGTH:
        return onUpdateInputLength(store, payload);
      default:
        return store;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const setMaxInputLength = useCallback((maxLength: number) => {
    dispatch({
      type: ActionTypes.SET_MAX_LENGTH,
      payload: { maxLength },
    });
  }, []);

  const updateInputLength = useCallback((inputLength: number) => {
    dispatch({
      type: ActionTypes.UPDATE_CURRENT_INPUT_LENGTH,
      payload: { inputLength },
    });
  }, []);

  return { state, setMaxInputLength, updateInputLength };
}
