import { PutChangePasswordRequestV1 } from './types';
import { fetchWithErrorThrowing } from 'ck-utils';
import { toast } from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

// Mutation hook
export const usePasswordChange = () => {
  return useMutation({
    mutationFn: (data: PutChangePasswordRequestV1) =>
      toast.promise(
        putChangePassword(data),
        {
          loading: 'Laddar...',
          success: 'Lösenordet är ändrat',
          error: 'Kunde inte ändra lösenordet',
        },
        {
          duration: 8000,
        }
      ),
  });
};

// Mutation function
const putChangePassword = (data: PutChangePasswordRequestV1) =>
  fetchWithErrorThrowing('/user/change-password', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
