import { PostCreateEventV1 } from './types';
import { useEventsCategories } from './useEventsCategories';

/*
 * Used before posting an event object to backend.
 *
 * Receiving an eventObj with a list of category-names,
 * convert it to category-ids and return the new eventObj
 *
 * Backend only communicates using categoryIds, while names
 * Are used in FE for user readability
 */
export const useQueryEventsCategories = () => {
  // Get the pairings of category-names and category-tags from BE
  const { data: categories } = useEventsCategories();
  return (data: PostCreateEventV1): PostCreateEventV1 => {
    if (categories) {
      return {
        ...data,
        categories: categories
          .filter((obj) => data.categories.includes(obj.name))
          .map((c) => c.id),
      };
    } else return data;
  };
};
