export function removeNullUndefinedProperties<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const modifiedObj: T = {} as T;

  for (const prop in obj) {
    if (obj[prop] !== null && obj[prop] !== undefined) {
      modifiedObj[prop] = obj[prop];
    }
  }

  return modifiedObj;
}
