import { QK } from '../queryKeys';
import { getUser } from './localQueries';
import { useQuery } from '@tanstack/react-query';

export const useUser = () => {
  return useQuery({
    queryKey: [QK.user],
    queryFn: getUser,
  });
};
