import { fetchWithErrorThrowing, useTypedNavigate } from 'src/utils';

import { APIError } from 'src/typesGlobal';
import { PutUpdatePasswordV1 } from './types';
import { toast } from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

// Mutation hook
export const usePasswordUpdate = () => {
  const { navigate } = useTypedNavigate();
  return useMutation({
    mutationFn: (data: PutUpdatePasswordV1) =>
      toast.promise(
        putUpdatePassword(data),
        {
          loading: 'Laddar...',
          success: 'Lösenord uppdaterat',
          error: 'Något gick fel',
        },
        {
          duration: 8000,
        }
      ),
    onSuccess: () => {
      navigate('/login');
    },
    // Since this is used outside of root component, we need to handle errors here
    onError: (error) => {
      if (error instanceof APIError) {
        toast.error(error.clientMessageSwedish);
      }
    },
  });
};

// Mutation function
const putUpdatePassword = (data: PutUpdatePasswordV1) =>
  fetchWithErrorThrowing('/password', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
