import { fetchWithErrorThrowing } from 'ck-utils';
import { toast } from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

export type PostResetPasswordRequestV1 = {
  email: string;
};

// Mutation hook
export const usePasswordReset = () => {
  return useMutation({
    mutationFn: (data: PostResetPasswordRequestV1) =>
      toast.promise(
        postResetPassword(data),
        {
          loading: 'Laddar...',
          success: 'Återställningslänk har skickats till din mail',
          error: 'Kunde inte återställa lösenordet',
        },
        {
          duration: 8000,
        }
      ),
  });
};

// Mutation function
async function postResetPassword(
  data: PostResetPasswordRequestV1
): Promise<void> {
  await fetchWithErrorThrowing('/password/reset', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
}
