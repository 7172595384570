export type heldInLanguage = 'English' | 'Swedish' | 'Not applicable';
export type EventReservationType = 'Open' | 'Free' | 'Paid';

// TODO would like to extract these from useEventsUserTypes
// For now, mimic GeneralSection and its UrlStateOptions
export enum targetUserTypeEnum {
  Students = 'Students',
  Alumnus = 'Alumnus',
  All = 'All',
  StudentsAtDivision = 'StudentsAtDivision',
}
export type targetUserType = keyof typeof targetUserTypeEnum;

export const targetUserTypeMap: { [key in targetUserType]: string } = {
  Students: 'Studenter',
  All: 'Studenter & Alumner',
  Alumnus: 'Alumner',
  StudentsAtDivision: 'Sektionsspecifik (studenter)',
};

export type EventIdName = {
  id: string;
  name: string;
};

export interface TicketType {
  swedishName: string;
  englishName: string;
  price: number;
}

export interface EventReservationDetails {
  ticketReleaseTime?: string;
  ticketReservationExpiryTime?: string;
  gatherFoodPreferences?: boolean | string;
  tickets?: Array<TicketType>;
  numberOfTickets?: number;
  swishStore?: string;
  accountNumber?: string;
  resultUnit?: string;
  reservationType: EventReservationType;
  currentTicketsBooked: number;
}

export interface ModifiedEventReservationDetails
  extends Omit<
    EventReservationDetails,
    'ticketReleaseTime' | 'ticketReservationExpiryTime'
  > {
  ticketReleaseTimeUtc?: string;
  ticketReservationExpiryTimeUtc?: string;
}

export interface PostCreateEventV1 {
  name: string;
  nameSwedish: string;
  description: string;
  descriptionSwedish: string;
  location: string;
  locationSwedish: string;
  eventLink?: string;
  linkButtonText?: string;
  linkButtonTextSwedish?: string;
  host: string;
  divisions: string[]; // 'Målgrupp' in the multi-select dropdown
  categories: string[];
  date: string;
  startTime: string;
  endTime: string;
  heldInLanguage: heldInLanguage;
  targetUserType: targetUserType; // student och/eller alumn
  image: File | null;
  imageCred?: string;
  isDraft: boolean;
  eventReservationDetails: Omit<
    EventReservationDetails,
    'currentTicketsBooked'
  >;
}

export type EventObj = {
  id: string;
  categoryIds: string[];
  name: string;
  nameSwedish: string;
  description: string;
  descriptionSwedish: string;
  createdBy: string;
  location: string;
  locationSwedish: string;
  eventLink?: string;
  linkButtonText?: string;
  linkButtonTextSwedish?: string;
  host: string;
  audience: string[];
  endTime: string;
  startTime: string;
  heldInLanguage: heldInLanguage;
  imageUrl?: string;
  imageCred?: string;
  updatedAt: string;
  targetUserType: targetUserType;
  eventReservationDetails: ModifiedEventReservationDetails;
};

export type EventsParticipants = {
  title: string;
  startDate: string;
  host: string;
  participants: number;
  totalParticipants: number;
  eventId: string;
};

export type ModifiedEventsParticipants = {
  title: string;
  startDate: string;
  host: string;
  participants: number;
  totalParticipants: number;
  eventId: string;
};

export type ConvertedEventsParticipants = EventsParticipants & {
  participantsQuota: string;
};
