import { createFormData, fetchWithErrorThrowing } from 'ck-utils';

import { PostPollsRequest } from './types';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

// Mutation hook
export const usePollsCreate = () =>
  useMutation({
    mutationFn: (data: PostPollsRequest) =>
      toast.promise(
        postPollsCreate(data),
        {
          loading: 'Laddar...',
          success: 'Omröstning skapad',
          error: 'Kunde inte skapa omröstning',
        },
        {
          duration: 8000,
        }
      ),
  });

// Mutation function
const postPollsCreate = ({ targetGroup, ...rest }: PostPollsRequest) => {
  const formData = createFormData({ targetGroup: targetGroup.group, ...rest });

  return fetchWithErrorThrowing('/polls', {
    method: 'POST',
    body: formData,
  });
};
