import { Bold, ConfirmationModal } from 'ck-components';

import { ConfirmationModalProps } from 'src/components/Modals/types';
import dayjs from 'dayjs';

interface Props
  extends Omit<
    ConfirmationModalProps,
    'modalTitle' | 'children' | 'confirmationLabel'
  > {
  isEdit?: boolean;
  userCount: string;
  isSchedule?: boolean;
  scheduleTime: string | null;
}

export const PollsCreateConfirmationModal = ({
  isEdit,
  userCount,
  isSchedule,
  scheduleTime,
  ...rest
}: Props) => {
  return (
    <ConfirmationModal
      modalTitle={
        isEdit
          ? 'Redigera omröstning'
          : isSchedule
          ? 'Schemalägg omröstning'
          : 'Skicka omröstning'
      }
      confirmationLabel={
        isEdit ? 'Spara redigering' : isSchedule ? 'Schemalägg' : 'Skicka nu'
      }
      {...rest}
    >
      <p className='max-w-md text-lg'>
        {isSchedule ? (
          <>
            Omröstningen kommer skickas
            <Bold> {dayjs(scheduleTime).format('D MMMM kl. HH:mm')}</Bold> till
            <Bold> {userCount} st. </Bold>
            Är du säker på att du vill schemalägga den?
          </>
        ) : (
          <>
            Omröstningen kommer skickas till <Bold>{userCount} st</Bold> direkt.
            Är du säker på att du vill skicka den nu?
          </>
        )}
      </p>
    </ConfirmationModal>
  );
};
