import {
  InformationModal,
  ModalColumn,
  ModalHorizontalDivider,
  ModalLabelAndText,
} from 'ck-components';

import { Poll } from 'ck-queries';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  data?: Poll;
}

// TODO: Move this to modals in components. THIS IS NOT A PAGE.

export const PollsArchiveDetailsModal = ({
  isOpen,
  setIsOpen,
  data,
}: Props) => {
  if (!data) return null;

  return (
    <InformationModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customStyle='md:min-w-[800px]'
    >
      {data && (
        <div>
          <div className='flex flex-row justify-between gap-2'>
            {/* SWEDISH */}
            <ModalColumn title='Svenska'>
              <ModalLabelAndText label='TITEL' text={data.titleSwedish} />
              <ModalLabelAndText label='FRÅGA' text={data.questionSwedish} />
              <div>
                <ModalLabelAndText label='SVAR' />
                {data.answers.map(({ answerSwedish }, i) => (
                  <p
                    key={`${answerSwedish}-${i}`}
                    className='whitespace-nowrap'
                  >
                    {`${i + 1}. ${answerSwedish}`}
                  </p>
                ))}
              </div>
            </ModalColumn>
            {/* ENGLISH */}
            <ModalColumn title='Engelska'>
              <ModalLabelAndText label='TITLE' text={data.titleEnglish} />
              <ModalLabelAndText label='QUESTION' text={data.questionEnglish} />
              <div>
                <ModalLabelAndText label='ANSWERS' />
                {data.answers.map(({ answerEnglish }, i) => (
                  <p
                    key={`${answerEnglish}-${i}`}
                    className='whitespace-nowrap'
                  >
                    {`${i + 1}. ${answerEnglish}`}
                  </p>
                ))}
              </div>
            </ModalColumn>
          </div>
          {(data.sent || data.imageUrl) && <ModalHorizontalDivider />}
          <div className='flex flex-row justify-between gap-2'>
            {data.sent && (
              <ModalColumn>
                <div className='whitespace-nowrap'>
                  <ModalLabelAndText label='RESULTAT' />
                  {data.answers.map((a, i) => (
                    <p key={`results-${i}`}>
                      {`${i + 1}. ${a.answerSwedish}`}{' '}
                      <span className='text-blue-400'>
                        {`${a.numberOfVotes} röster (${a.numberOfVotesPercentage}%)`}
                      </span>
                    </p>
                  ))}
                </div>
              </ModalColumn>
            )}
            {data.imageUrl && (
              <ModalColumn>
                <div>
                  <ModalLabelAndText label='BILD' />
                  <img src={data.imageUrl} className='max-w-[200px]' />
                </div>
              </ModalColumn>
            )}
          </div>
          <ModalHorizontalDivider />
          {/* INFO */}
          <div className='flex flex-col justify-between gap-4 md:flex-row'>
            <ModalColumn>
              <ModalLabelAndText
                label='VISAS FÖR'
                text={data.targetGroupString}
              />
            </ModalColumn>
            <ModalColumn>
              <ModalLabelAndText label='SKAPAD AV' text={data.createdBy} />
            </ModalColumn>
            <ModalColumn>
              <ModalLabelAndText
                label='ÖPPNAD AV'
                text={String(data.openedByString)}
              />
            </ModalColumn>
            <ModalColumn className='md:self-center'>
              <p className='whitespace-nowrap font-thin italic'>
                {data.sent
                  ? `Skickad ${data.sent}`
                  : `Skickas ${data.scheduleTime}`}
              </p>
            </ModalColumn>
          </div>
        </div>
      )}
    </InformationModal>
  );
};
