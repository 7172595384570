import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QK } from '../queryKeys';
import { fetchWithErrorThrowing } from 'ck-utils';
import toast from 'react-hot-toast';

export const useEditCommittee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { id: string; email: string }) =>
      toast.promise(
        editCommittee(data),
        {
          loading: 'Laddar...',
          success: 'Kommitté redigerad',
          error: 'Kunde inte redigera kommitté',
        },
        {
          duration: 8000,
        }
      ),
    onSettled: () => {
      queryClient.invalidateQueries([QK.committees]);
    },
  });
};

function editCommittee({ id, email }: { id: string; email: string }) {
  return fetchWithErrorThrowing(`/committees/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ email }),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
}
