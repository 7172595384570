import { ButtonBase, ButtonBaseProps } from './ButtonBase';

import { clsx } from 'clsx';

interface CancelButtonProps extends ButtonBaseProps {
  isDelete?: boolean;
}

/**
 *
 * CancelButton with standard blue color scheme or red color scheme if isDelete is true.
 *
 * @param onClick the callback function for when the button is clicked
 * @param label the text displayed on the button
 * @param disabled decides whether the button is disabled or not
 * @param buttonType decides the styling of the button
 * @param className additional custom styling
 * @param iconLeft the icon to be displayed on the left of the button
 * @param iconStyle additional custom styling for the icon
 * @param isDelete decides whether the button is red or blue
 * @returns
 */

export function CancelButton(props: CancelButtonProps) {
  const { isDelete, className } = props;

  return (
    <ButtonBase
      {...props}
      className={clsx(
        isDelete
          ? 'border-rose-600 bg-white text-rose-500 hover:border-rose-600 hover:bg-white hover:text-rose-600 active:bg-white active:text-rose-500 disabled:border-rose-300 disabled:bg-white disabled:text-rose-300'
          : 'border-sky-500 bg-white text-sky-500 hover:border-sky-600 hover:bg-white hover:text-sky-600  active:bg-white active:text-sky-500 disabled:border-sky-300 disabled:bg-white disabled:text-sky-300',
        className // Custom styling
      )}
    />
  );
}
