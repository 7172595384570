import { PaginationContext } from './TableWithPagination';
import clsx from 'clsx';
import { useContext } from 'react';

interface PaginationNumberButtonProps {
  index?: number;
}

/**
 * Renders the button containing the page number.
 *
 * @param index the array index of the page we are referencing
 */

export default function PaginationNumberButton({
  index = 0,
}: PaginationNumberButtonProps) {
  const { state, changePage } = useContext(PaginationContext);
  return (
    <span
      className={clsx(
        'text-lg hover:cursor-pointer',
        index === state.currentPage && 'font-semibold'
      )}
      onClick={() => changePage(index)}
    >
      {index + 1}
    </span>
  );
}
