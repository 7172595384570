import { PushNotification } from './types';
import { QK } from '../queryKeys';
import { fetchWithErrorThrowing } from 'ck-utils';
import { formatPushNotifications } from './localUtils';
import { useQuery } from '@tanstack/react-query';

interface PushNotificationResponse {
  notifications: PushNotification[];
}

// Query hook
export const useNotificationsHistory = () => {
  return useQuery<PushNotification[]>({
    queryKey: QK.notifications.history,
    queryFn: getNotificationsHistory,
  });
};

// Query function
export const getNotificationsHistory = (): Promise<PushNotification[]> =>
  fetchWithErrorThrowing('/notifications', {
    method: 'GET',
  })
    .then((res) => res.json())
    .then(({ notifications }: PushNotificationResponse) => notifications);

// Optional query function to get the notifications formatted
// Used in the 'ArchiveTab.tsx' where a queryConfig is passed in
export const getNotificationsHistoryFormatted = () =>
  getNotificationsHistory().then(formatPushNotifications);
