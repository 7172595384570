import { ArrElement } from '../Table/Table';
import { useState } from 'react';

export interface PaginationState<T> {
  currentPage: number;
  dataToDisplay: T;
  formattedData: any[];
  numberOfPages: number;
}

interface InitialReducerProps<T> {
  elementsPerPage: number;
  data: T;
}

/**
 * Keeps track of the state for the pagination.
 * Splits data into page sections of size 'elementsPerPage'. These sections
 * are what is being rendered on each table page when navigating.
 * The reducer thereafter derives the dataToDisplay based on the currentPage.
 */

export default function usePaginationState<
  T extends Array<{ [key in keyof ArrElement<T>]: any }>
>({ elementsPerPage, data }: InitialReducerProps<T>) {
  const [currentIndex, changePage] = useState(0);

  const state: PaginationState<T> = (() => {
    const pagesData = [];

    for (let i = 0; i < data.length; i += elementsPerPage) {
      const page = data.slice(i, i + elementsPerPage);
      pagesData.push(page);
    }

    // If page is empty, go to previous
    if (!pagesData[currentIndex]) {
      changePage((prev) => prev - 1);
    }

    return {
      currentPage: currentIndex,
      dataToDisplay: pagesData[currentIndex] as T,
      formattedData: pagesData,
      numberOfPages: pagesData.length,
    };
  })();

  return { state, changePage };
}
