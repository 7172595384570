/**
 * Darkens a given hex color by a specified amount.
 *
 * @param {string} hex - The hex code of the color to darken.
 * @param {number} [amount=0.2] - The amount by which to darken the color (a value between 0 and 1).
 * @returns {string} The hex code of the darkened color.
 */
export function darkenColor(hex: string, amount = 0.2): string {
  // Check if the input is a valid hex code
  if (!/^#[0-9a-fA-F]{6}$/.test(hex)) {
    console.warn(`Invalid hex code: ${hex}`);
    return hex;
  }

  // Convert hex to RGB
  const rgb = {
    r: parseInt(hex.substring(1, 3), 16),
    g: parseInt(hex.substring(3, 5), 16),
    b: parseInt(hex.substring(5, 7), 16),
  };

  // Darken each RGB component
  const darkenedRgb = {
    r: Math.round(rgb.r * (1 - amount)),
    g: Math.round(rgb.g * (1 - amount)),
    b: Math.round(rgb.b * (1 - amount)),
  };

  // Convert RGB back to hex
  const darkenedHex = `#${[
    darkenedRgb.r.toString(16).padStart(2, '0'),
    darkenedRgb.g.toString(16).padStart(2, '0'),
    darkenedRgb.b.toString(16).padStart(2, '0'),
  ].join('')}`;

  return darkenedHex;
}
