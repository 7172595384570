import Checkmark from '../../assets/icons/Checkmark.svg';

interface CheckboxProps {
  checked?: boolean;
  label: string;
}

/**
 * This is the reusable component for a checkbox, to be used in multiple choice dropdowns.
 *  It is purely decorative and (so far) only used in <MultiSelectDropDown> to show what's been selected.s
 *
 * @param label the entry in the data object to be used as checkbox label.
 * @param checked the initial value for the checkbox
 */

export default function Checkbox({ checked = false, label }: CheckboxProps) {
  return (
    <div className='flex w-full gap-3 px-3 py-1'>
      <div className='h-6 w-6 rounded-md border-2 border-sky-500'>
        {checked && (
          <div
            className=' 
              flex h-full
            w-full flex-row
            content-center
            justify-center
            bg-sky-500'
          >
            <img className='scale-125 transform' src={Checkmark} />
          </div>
        )}
      </div>

      {label}
    </div>
  );
}
