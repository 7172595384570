import {
  PushNotification,
  PushNotificationFormatted,
  ScheduledNotification,
  ScheduledNotificationFormatted,
} from './types';

import dayjs from 'dayjs';
import { standardDateFormat } from 'src/utils';
import { TargetGroup } from '../general';

/**
 * Takes in notifications from server and formats objects into strings to display in table.
 *
 * @param notifications - PushNotifications from server
 * @returns - Formatted PushNotifications to work with table implementation
 */
export const formatPushNotifications = (notifications: PushNotification[]) => {
  if (!notifications) return []; // Nullcheck

  return notifications.map(
    ({ targetGroups, totalSeen, totalSent, date, ...rest }) => {
      return {
        ...rest,
        ...convertValuesToStrings(date, targetGroups),
        openingStats: `${totalSeen} st av ${totalSent} st`,
      } as PushNotificationFormatted;
    }
  );
};

export const formatScheduledPushNotifications = (
  notifications: ScheduledNotification[]
) => {
  if (!notifications) return [];

  return notifications.map(
    ({ targetGroups, date, ...rest }) =>
      ({
        ...rest,
        ...convertValuesToStrings(date, targetGroups),
        scheduleTime: dayjs(rest.scheduleTime).format('YYYY-MM-DD HH:mm'),
        scheduleTimeFormatted: dayjs(rest.scheduleTime).format(
          standardDateFormat
        ),
      } as ScheduledNotificationFormatted)
  );
};

// Helper function
const convertValuesToStrings = (date: string, targetGroups: TargetGroup[]) => {
  return {
    date: dayjs(date).format(standardDateFormat),
    targetGroupString: targetGroups.map((t) => `${t.group} (${t.count} st)`)[0],
  };
};
