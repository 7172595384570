import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QK } from '../queryKeys';
import { SwishStore } from './types';
import { fetchWithErrorThrowing } from 'ck-utils';
import toast from 'react-hot-toast';

export const useSwishStoreCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SwishStore) =>
      toast
        .promise(postCreateSwish(data), {
          loading: 'Laddar...',
          success: 'Swishkassa skapad',
          error: 'Kunde inte skapa swishkassa',
        })
        .then(() => {
          return data;
        }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QK.swishStores],
      });

      await queryClient.refetchQueries({ queryKey: [QK.swishStores] });
    },
  });
};

type PostSwishStore = Pick<SwishStore, 'committeeId' | 'swishStore'>;

async function postCreateSwish({ committeeId, swishStore }: PostSwishStore) {
  return fetchWithErrorThrowing('/swish-stores', {
    method: 'POST',
    body: JSON.stringify({
      committeeId: committeeId,
      swishStore: swishStore,
    }),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
}
