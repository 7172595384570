import { PushNotification, ScheduledNotification } from 'ck-queries';

import dayjs from 'dayjs';
import { removeNullUndefinedProperties } from './removeNullUndefinedProperties';

const scheduleTimeDefault = dayjs()
  .add(1, 'week')
  .set('hour', 8)
  .set('minutes', 0)
  .format('YYYY-MM-DD hh:mm');

export const formatNotificationPrefill = async (
  rawPrefill: PushNotification | ScheduledNotification | undefined,
  isCopy: boolean
) => {
  if (!rawPrefill) return Promise.resolve({ prefill: undefined });

  const isScheduledNotification = (obj: any): obj is ScheduledNotification => {
    return obj && typeof obj.scheduleTime !== 'undefined';
  };

  let scheduleTime;

  const prefill = removeNullUndefinedProperties(rawPrefill);

  const titleSwedish = isCopy
    ? `Kopia av ${prefill.titleSwedish}`
    : prefill.titleSwedish;

  // Check if rawPrefill is ScheduledNotification
  if (isScheduledNotification(prefill)) {
    // If rawPrefill is ScheduledNotification, proceed with accessing scheduleTime
    scheduleTime = dayjs(prefill.scheduleTime).format('YYYY-MM-DDTHH:mm');
  }

  const titleEnglish = isCopy
    ? `Copy of ${prefill.titleEnglish}`
    : prefill.titleEnglish;

  const formattedPrefill: ScheduledNotification = {
    ...prefill,
    scheduleTime: scheduleTime ?? scheduleTimeDefault,
    titleEnglish: titleEnglish,
    titleSwedish: titleSwedish,
  };

  return { prefill: formattedPrefill };
};
