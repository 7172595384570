import { AuthorizedFunction, useAuth } from 'ck-queries';

import { TypedNavigate } from 'src/utils';

/**
 * This component redirects the user to the most relevant start page based on their role and permissions.
 * @returns A component that renders a TypedNavigate component to redirect to the appropriate start page.
 */
export const RedirectToRelevantStartPage = () => {
  const { user } = useAuth();

  // Simple helper function to check if the user has a specific function
  const isAllowed = (func: AuthorizedFunction) =>
    user?.functions.includes(func);

  // If the user is not logged in, redirect to the login page
  if (!user) return <TypedNavigate to={'/login'} />;

  // If the user has access to PushNotification, that page is prioritized
  if (isAllowed('PushNotification')) {
    return <TypedNavigate to={'/push-notifications/create'} />;
  }

  // If the user has access to CustomTheme, that page is prioritized
  if (isAllowed('CustomTheme')) {
    return <TypedNavigate to={'/themes/create'} />;
  }

  if (isAllowed('InviteDivisionAdmin')) {
    return <TypedNavigate to={'/organisations'} />;
  }

  if (isAllowed('Events')) {
    return <TypedNavigate to={'/events/create'} />;
  }

  // Absolute default case, all users will have a settings page
  return <TypedNavigate to={'/settings'} />;
};
