import React from 'react';
import { clsx } from 'clsx';

interface Props {
  onPress: () => void;
  label: string;
  disabled?: boolean;
  customStyle?: string;
}

/**
 *
 * @param onPress the callback function for when the text is pressed
 * @param label the displayed text
 * @param disabled decides whether the button is disabled or not
 * @param buttonType decides the styling of the button
 * @param customStyle additional custom styling
 * @returns
 */

export function ClickableText({
  onPress,
  label,
  disabled = false,
  customStyle,
}: Props) {
  return (
    <button
      onClick={disabled ? () => undefined : () => onPress()}
      className={clsx(customStyle, 'w-fit border-none p-2')}
      disabled={disabled}
    >
      {label}
    </button>
  );
}
