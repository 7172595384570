import clsx from 'clsx';

interface TitleProps {
  label: string;
  customStyle?: string;
}

/**
 * A styled component for displayed sub-titles on pages/components.
 *
 * @param label the text to be displayed
 * @param customStyle optional styling
 */

export function SubTitle({ label, customStyle }: TitleProps) {
  return (
    <div
      className={clsx('mb-4 w-full text-left text-xl font-bold', customStyle)}
    >
      {label}
    </div>
  );
}
