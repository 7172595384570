import { QK } from '../queryKeys';
import { formatEventPrefill } from '../../utils/formatEventPrefill';
import { useEventsActive } from './useEventsActive';
import { useEventsCategories } from './useEventsCategories';
import { useEventsDraft } from './useEventsDraft';
import { useEventsHistory } from './useEventsHistory';
import { useQuery } from '@tanstack/react-query';

export const useEventsPrefill = (
  eventId: string | undefined,
  isCopy: boolean
) => {
  const { data: activeEvents } = useEventsActive();
  const { data: historyEvents } = useEventsHistory();
  const { data: categories } = useEventsCategories();
  const { data: draftEvents } = useEventsDraft();

  const events = [
    ...(activeEvents ?? []),
    ...(historyEvents ?? []),
    ...(draftEvents ?? []),
  ];

  return useQuery({
    queryKey: [...QK.events.prefill, eventId, isCopy],
    queryFn: async () => {
      const maybeEvent = events.find(({ id }) => id === eventId);
      return formatEventPrefill(maybeEvent, isCopy, categories);
    },
    enabled: Boolean(activeEvents) && Boolean(categories),
  });
};
