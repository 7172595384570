import { FieldValues, Path, PathValue } from 'react-hook-form';

import Checkbox from '../Checkbox';
import { Listbox } from '@headlessui/react';

interface Props<T extends FieldValues> {
  option: PathValue<T, Path<T>>;
  currentSelected: PathValue<T, Path<T>>[];
}

export const ListOption = <T extends FieldValues>({
  option,
  currentSelected,
}: Props<T>) => {
  return (
    <Listbox.Option
      as='div'
      className='flex h-10 w-full cursor-pointer items-center px-4'
      value={option}
    >
      <li className='flex w-full items-center justify-between'>
        <Checkbox label={option} checked={currentSelected.includes(option)} />
      </li>
    </Listbox.Option>
  );
};
