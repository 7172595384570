import { PostLoginRequestV1, User } from './types';

import { fetchWithErrorThrowing } from 'src/utils';

export async function getUser(): Promise<User> {
  const res = await fetchWithErrorThrowing('/user', {
    method: 'GET',
  });

  return await res.json();
}

export const postLogin = (data: PostLoginRequestV1) =>
  fetchWithErrorThrowing('/auth/login', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({ 'content-type': 'application/json' }),
  });

export const postLogout = () =>
  fetchWithErrorThrowing('/auth/logout', {
    method: 'POST',
  });
