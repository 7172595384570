import { ButtonBase, ButtonBaseProps } from './ButtonBase';

import { clsx } from 'clsx';

interface ConfirmButtonProps extends ButtonBaseProps {
  isDelete?: boolean;
}

/**
 *
 * ConfirmButton with standard blue color scheme or red color scheme if isDelete is true.
 *
 * @param onClick the callback function for when the button is clicked
 * @param label the text displayed on the button
 * @param disabled decides whether the button is disabled or not
 * @param buttonType decides the styling of the button
 * @param className additional custom styling
 * @param iconLeft the icon to be displayed on the left of the button
 * @param iconStyle additional custom styling for the icon
 * @param isDelete decides whether the button is red or blue
 * @returns
 */

export function ConfirmButton(props: ConfirmButtonProps) {
  const { isDelete, className } = props;

  return (
    <ButtonBase
      {...props}
      className={clsx(
        isDelete
          ? 'border-rose-600 bg-rose-600 text-white hover:border-rose-700 hover:bg-rose-700 active:border-rose-600 active:bg-rose-600 disabled:cursor-not-allowed disabled:border-rose-300 disabled:bg-rose-300 disabled:text-rose-100'
          : 'border-sky-500 bg-sky-500 text-white hover:border-sky-600 hover:bg-sky-600 active:border-sky-500 active:bg-sky-500  disabled:border-sky-300 disabled:bg-sky-300 disabled:text-sky-100',
        className // Custom styling
      )}
    />
  );
}
