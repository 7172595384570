import { ButtonHTMLAttributes } from 'react';
import { LoadingSpinner } from 'ck-components';
import { ReactSVG } from 'react-svg';
import { clsx } from 'clsx';

export interface ButtonBaseProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  className?: string;
  iconLeft?: string;
  iconStyle?: string;
  isLoading?: boolean;
}

/**
 *
 * A base button component with no colors defined that can be used to create
 * other buttons.
 *
 * @param onClick the callback function for when the button is clicked
 * @param label the text displayed on the button
 * @param disabled decides whether the button is disabled or not
 * @param buttonType decides the styling of the button
 * @param className additional custom styling
 * @param iconLeft the icon to be displayed on the left of the button
 * @param iconStyle additional custom styling for the icon
 * @param isLoading decides whether the loading spinner should be displayed
 * @returns
 */

export const ButtonBase = ({
  onClick,
  label,
  disabled = false,
  className,
  iconLeft,
  iconStyle,
  isLoading,
}: ButtonBaseProps) => {
  return (
    <button
      type='button'
      onClick={disabled ? () => undefined : () => onClick()}
      disabled={disabled}
      className={clsx(
        'flex w-[180px] items-center justify-center gap-1 rounded-2xl border-2 py-4 px-5  text-base font-semibold disabled:cursor-not-allowed',
        className
      )}
    >
      {isLoading ? (
        <LoadingSpinner className='w-6' />
      ) : (
        <>
          {iconLeft && <ReactSVG src={iconLeft} className={iconStyle} />}
          {label}
        </>
      )}
    </button>
  );
};
