import { getUser, postLogin, postLogout } from './localQueries';

import { AuthContext } from './AuthProvider';
import { PostLoginRequestV1 } from './types';
import { localStorageTyped } from 'src/utils';
import toast from 'react-hot-toast';
import { useContext } from 'react';
import { useInitErrorHandling } from './useInitErrorHandling';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRefreshToken } from './useRefreshToken';

// Abstraction which wraps the context
export const useAuth = () => {
  // Retrieve the values from the context
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within the AuthProvider');
  }

  const { user, setUser } = context;
  const navigate = useNavigate();

  // Mutation for login
  const M_login = useMutation({
    mutationFn: (data: PostLoginRequestV1) =>
      toast.promise(
        postLogin(data),
        {
          loading: 'Laddar...',
          success: 'Du är inloggad!',
          error: 'Kunde inte logga in',
        },
        {
          duration: 8000,
        }
      ),
    onSuccess: () => {
      getUser().then((user) => {
        setUser(user);
        navigate('/');
      });
    },
    retry: false,
  });

  const logout = () => {
    postLogout();
    localStorageTyped.clear();
    setUser(null);
  };

  // Init Query/Mutation error handling
  useInitErrorHandling(logout);

  // Init refresh token interval
  useRefreshToken(user);

  return {
    user,
    M_login,
    logout,
  };
};
