import clsx from 'clsx';

interface TagProps {
  label: string;
  customStyle?: string;
  customTextStyle?: string;
}

/**
 * This wrapper component displays a label within a pill-shaped tag.
 * The default background color is blue, but can be changed with the customstyle prop.
 *
 * @param label the text to be displayed within the tag
 * @param customStyle optional styling (can be used to for example change the color of the tag)
 */

export function Tag({ label, customStyle, customTextStyle }: TagProps) {
  return (
    <div
      className={clsx(
        'flex h-10 items-center rounded-full py-2.5 px-7 ',
        customStyle
      )}
    >
      <span className={clsx('text-base font-bold text-white', customTextStyle)}>
        {label}
      </span>
    </div>
  );
}
