import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PostPushNotification } from './types';
import dayjs from 'dayjs';
import { fetchWithErrorThrowing } from 'ck-utils';
import { standardDateFormat } from '../../utils/constants';
import { toast } from 'react-hot-toast';

// Mutation hook
export const useNotificationsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PostPushNotification) =>
      toast.promise(
        createNotification(data),
        {
          loading: 'Laddar...',
          success: data.isDraft
            ? 'Utkast sparat'
            : data.scheduleTime
            ? `Pushnotis schemalagd \ntill ${dayjs(data.scheduleTime).format(
                standardDateFormat
              )}`
            : 'Pushnotis skickad',
          error: 'Kunde inte skapa pushnotis',
        },
        {
          duration: 8000,
        }
      ),
    // If connection is lost we don't want it to queue up mutations
    networkMode: 'always',
    onSuccess: () => {
      // Invalidate all notifications queries to update the UI
      queryClient.invalidateQueries(['notifications']);
    },
  });
};

// Mutation call
async function createNotification({
  targetGroup: { group: name },
  ...data
}: PostPushNotification) {
  return fetchWithErrorThrowing('/notifications', {
    method: 'POST',
    body: JSON.stringify({ ...data, targetGroup: name }), // API only accepts a string of the name
    headers: new Headers({ 'content-type': 'application/json' }),
  });
}
