import { FormInputProps, FormTextInput } from './FormTextInput';

import { FieldValues } from 'react-hook-form';

/**
 * Legacy component. Use FormTextInput instead with isPassword prop.
 *
 */

export function PasswordInput<T extends FieldValues>(props: FormInputProps<T>) {
  return <FormTextInput {...props} isPassword />;
}
