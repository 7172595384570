import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form';

import { ListboxWithSearch } from './ListBoxWithSearch';

interface Props<T extends FieldValues> {
  parentForm: UseFormReturn<T>;
  optionsData: PathValue<T, Path<T>>[];
  name: Path<T>;
  horizontal?: boolean | undefined;
  label: string;
  description?: string;
}

/**
 * MultiSelectDropdown component that allows selecting multiple options from a dropdown menu
 */
export const MultiSelectDropdown = <T extends FieldValues>({
  optionsData,
  name,
  parentForm,
  description,
  label,
}: Props<T>) => {
  const { control } = parentForm;

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <ListboxWithSearch
          parentForm={parentForm}
          name={name}
          formLabel={label}
          description={description}
          optionsData={optionsData}
        />
      )}
    />
  );
};
