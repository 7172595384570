import clsx from 'clsx';

interface Props {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  ignoreGap?: boolean;
}

export const ModalColumn = ({
  title,
  children,
  className,
  ignoreGap = false,
}: Props) => (
  <div
    className={clsx('flex flex-1 flex-col', !ignoreGap && 'gap-6', className)}
  >
    {title && <h2 className='text-xl font-bold'>{title}</h2>}
    <div className={clsx(ignoreGap && 'h-6')} />
    {children}
  </div>
);
