import { EventObj } from './types';
import { QK } from '../queryKeys';
import { fetchWithErrorThrowing } from 'ck-utils';
import { formatEvents } from './localUtils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useEventsActive = () =>
  useQuery({
    queryKey: QK.events.active,
    queryFn: getEventsActive,
  });

// Query function
export async function getEventsActive(): Promise<EventObj[]> {
  const res = await fetchWithErrorThrowing('/events/active', {
    method: 'GET',
  });

  const data: { events: EventObj[] } = await res.json();
  return formatEvents(data.events);
}
