import { PostCreateCommittee, QK } from 'ck-queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchWithErrorThrowing } from '../../utils/fetchWithErrorThrowing';
import { toast } from 'react-hot-toast';

// Mutation hook
export const useCreateCommittee = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PostCreateCommittee) =>
      toast.promise(
        createCommittee(data),
        {
          loading: 'Laddar...',
          success: `Kommitté skapad`,
          error: 'Kunde inte skapa kommittén',
        },
        {
          duration: 8000,
        }
      ),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QK.committees],
      });
    },
  });
};

// Mutation function
const createCommittee = (data: PostCreateCommittee) =>
  fetchWithErrorThrowing('/committees/invite-moderator', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
