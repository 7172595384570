import { createFormData, fetchWithErrorThrowing } from 'ck-utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GK } from '../queryKeys';
import { ThemeFormData } from './types';
import { toast } from 'react-hot-toast';

export const useThemeCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ThemeFormData) =>
      toast.promise(postCreateTheme(data), {
        loading: 'Laddar...',
        success:
          data.status === 'upcoming'
            ? 'Temat skapades'
            : 'Temat sparades som utkast',
        error: 'Kunde inte spara temat',
      }),
    onSuccess: () => {
      // Invalidate the query so the UI updates with the new themes
      queryClient.invalidateQueries(GK.themes);
    },
  });
};

async function postCreateTheme({ targetGroup, theme, ...rest }: ThemeFormData) {
  // Create form data from the data object
  // This is needed to send the pngImage file
  const formData = createFormData({
    ...rest,
    targetGroup: targetGroup.group, // Only send the targetGroup.group as a string
    theme: JSON.stringify(theme), // Convert theme to string to fit backend spec
  });
  return fetchWithErrorThrowing('/themes', {
    method: 'POST',
    body: formData,
  });
}
