import { EditInfo, LoadingSpinner, MainTitle } from 'ck-components';
import { Navigate, useParams } from 'react-router-dom';

import { CreatePushNotificationForm } from 'src/widgets';
import { useNotificationPrefill } from 'src/queries/push-notifications/useNotificationPrefill';

interface Props {
  isEdit?: boolean;
  isCopy?: boolean;
}
export function PushNotificationsCreatePage({ isCopy, isEdit }: Props) {
  // Id provided by the route when editing a notification
  const { id } = useParams();
  const { data, isLoading } = useNotificationPrefill(id, Boolean(isCopy));

  // Get the values of the notification to edit
  const prefill = data?.prefill;

  if (isLoading) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingSpinner width={100} />
      </div>
    );
  }

  if (!prefill && id) {
    return <Navigate to='/push-notifications/create' replace={true} />;
  }

  const mainTitle = isEdit
    ? 'Redigerar pushnotis'
    : isCopy && prefill?.titleSwedish
    ? prefill.titleSwedish
    : 'Skapa pushnotis';

  return (
    <div className='flex flex-1 flex-col'>
      {prefill && <EditInfo isDraft={isEdit && prefill.isDraft} />}
      <MainTitle label={mainTitle} customStyle='pb-8' />

      <CreatePushNotificationForm
        isEdit={isEdit}
        prefill={prefill}
        isCopy={isCopy}
      />
    </div>
  );
}
