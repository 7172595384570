import {
  AddAdminModal,
  AddCommitteeModal,
  ChangeModeratorModal,
  RemoveAdminModal,
  RemoveCommitteeModal,
} from 'src/widgets';
import {
  Admin,
  Committee,
  useAdmins,
  useAuth,
  useCommittees,
  useUserDivision,
} from 'ck-queries';
import {
  BreadCrumbs,
  Button,
  LoadingSpinner,
  MainTitle,
  TabTitle,
  TableWithPagination,
} from 'ck-components';

import { Plus } from 'src/assets';
import { Tab } from '@headlessui/react';
import { TypedNavigate } from 'src/utils';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

export function DivisionPage() {
  const { divisionId } = useParams() as {
    divisionId: string;
  };
  const { user } = useAuth();

  const isSuperAdmin = Boolean(user?.roles.includes('SuperAdmin'));

  // ---- Queries ----
  const Q_admins = useAdmins(divisionId);
  const Q_committees = useCommittees(divisionId);
  const Q_userDivision = useUserDivision(divisionId);

  // ---- State ----
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [addAdminModalOpen, setAddAdminModalOpen] = useState(false);
  const [addCommitteeModalOpen, setAddCommitteeModalOpen] = useState(false);
  const [changeModeratorModalOpen, setChangeModeratorModalOpen] =
    useState(false);
  const [removeAdminModalOpen, setRemoveAdminModalOpen] = useState(false);
  const [removeCommitteeModalOpen, setRemoveCommitteeModalOpen] =
    useState(false);
  const [adminToRemoveId, setAdminToRemoveId] = useState('');
  const [adminToRemoveName, setAdminToRemoveName] = useState('');
  const [rowCommitteeData, setRowCommitteeData] = useState<Committee | null>(
    null
  );

  if (Q_userDivision.isLoading) {
    return (
      <div className='flex w-full items-center justify-center'>
        <LoadingSpinner width={100} />
      </div>
    );
  }

  if (Q_userDivision.isError) {
    toast.error('Ett fel uppstod vid inläsning av sektionen.');
    return <TypedNavigate to={isSuperAdmin ? '/organisations' : '/'} />;
  }

  if (!Q_userDivision.data) {
    toast.error('Sektionen kunde inte hittas.');
    return <TypedNavigate to={isSuperAdmin ? '/organisations' : '/'} />;
  }

  const { id, name } = Q_userDivision.data;

  return (
    <div className='flex w-full gap-10' key={`page-${id}`}>
      {/* ------------------ MODALS ------------------ */}
      {/* TODO: Some of there modal components can be merged */}
      <AddAdminModal
        modalTitle={`Lägg till admin för ${name}`}
        divisionId={id}
        isOpen={addAdminModalOpen}
        setIsOpen={setAddAdminModalOpen}
      />
      <AddCommitteeModal
        modalTitle={`Ny kommitté`}
        divisionId={id}
        isOpen={addCommitteeModalOpen}
        setIsOpen={setAddCommitteeModalOpen}
      />
      <RemoveAdminModal
        modalTitle={`Ta bort admin för ${name}`}
        name={adminToRemoveName}
        id={adminToRemoveId}
        isOpen={removeAdminModalOpen}
        setIsOpen={setRemoveAdminModalOpen}
      />
      {rowCommitteeData && (
        <RemoveCommitteeModal
          modalTitle={'Ta bort kommitté'}
          committeeId={rowCommitteeData.id}
          committeeName={rowCommitteeData.name}
          isOpen={removeCommitteeModalOpen}
          setIsOpen={setRemoveCommitteeModalOpen}
        />
      )}
      {rowCommitteeData && (
        <ChangeModeratorModal
          modalTitle={`Byt moderator för ${rowCommitteeData.name}`}
          committeeId={rowCommitteeData.id}
          committeeName={rowCommitteeData.name}
          isOpen={changeModeratorModalOpen}
          setIsOpen={setChangeModeratorModalOpen}
        />
      )}
      <div className='flex w-full flex-col gap-6 '>
        {isSuperAdmin && (
          <BreadCrumbs
            pathList={[['Organisationer', '/organisations'], [name]]}
          />
        )}
        <div className='flex'>
          <MainTitle label={name} />
          {/* ------- Create buttons ------ */}
          <div className='flex-shrink-0'>
            {selectedIndex === 0 && (
              <Button
                customStyle='whitespace-nowrap text-lg '
                iconLeft={Plus}
                label='Ny kommitté'
                onClick={() => setAddCommitteeModalOpen(true)}
              />
            )}
            {/* Only show Create Admin if it is superAdmin */}
            {isSuperAdmin && selectedIndex === 1 && (
              <Button
                customStyle='whitespace-nowrap text-lg'
                iconLeft={Plus}
                label='Ny admin'
                onClick={() => setAddAdminModalOpen(true)}
              />
            )}
          </div>
        </div>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className='my-2 flex gap-7 border-b-2 border-neutral-200 outline-none'>
            <TabTitle label='KOMMITTÉER' />
            <TabTitle label='ADMIN' />
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className='outline-none'>
              {/* TODO: Break this and relevant modals out to its own component */}
              {Q_committees.isLoading && (
                <div className='flex w-full items-center justify-center'>
                  <LoadingSpinner width={100} />
                </div>
              )}
              {Q_committees.isError && (
                <>
                  Något gick fel vid inläsning. <br />
                  Prova uppdatera webbläsaren.
                </>
              )}
              {Q_committees.data?.length === 0 && (
                <>Inga kommittéer hittades.</>
              )}
              {Q_committees.isSuccess && Q_committees.data.length > 0 && (
                <TableWithPagination
                  data={Q_committees.data}
                  elementsPerPage={10}
                  tableColHeadings={['Kommittéer', 'Moderator', 'Status']}
                  tableRowDataPaths={['name', 'activeEmail', 'status']}
                  onEditPress={(data: Committee) => {
                    // Pass data to the modal and open it
                    setRowCommitteeData(data);
                    setChangeModeratorModalOpen(true);
                  }}
                  onDeletePress={(data: Committee) => {
                    // Pass data to the modal and open it
                    setRowCommitteeData(data);
                    setRemoveCommitteeModalOpen(true);
                  }}
                />
              )}
            </Tab.Panel>
            <Tab.Panel className='outline-none'>
              {/* TODO: Break this and relevant modals out to its own component */}
              {Q_admins.data && Q_admins.data.length > 0 ? (
                <TableWithPagination
                  data={Q_admins.data}
                  elementsPerPage={10}
                  tableColHeadings={['Administratörer', 'Status']}
                  tableRowDataPaths={['email', 'status']}
                  onDeletePress={
                    // Only show delete button if it is superAdmin
                    isSuperAdmin
                      ? (data: Admin) => {
                          setAdminToRemoveId(data.inviteId);
                          setAdminToRemoveName(data.email);
                          setRemoveAdminModalOpen(true);
                        }
                      : undefined
                  }
                />
              ) : (
                'Inga administratörer hittades.'
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}
