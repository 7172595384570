import { ContentCard, MainTitle } from 'ck-components';

import { CurrentUserCard } from 'src/widgets';

export function SettingsPage() {
  return (
    <div className='flex flex-1 flex-col justify-start'>
      <MainTitle label='Inställningar' customStyle='pb-8 pt-28' />
      <div>
        <ContentCard customStyle='w-full'>
          <CurrentUserCard />
        </ContentCard>
      </div>
    </div>
  );
}
