import { PaginationContext } from './TableWithPagination';
import { useContext } from 'react';

interface PaginationCollapseButtonProps {
  nrOfPaginationNumbers: number;
  jumpBackwards?: boolean;
}

/**
 * This component returns the three dots used to quickly navigate the table pages.
 * The default behaviour is to jump nrOfPaginationNumbers pages forward or backwards, but there are some additional
 * calculations done to make sure we're not hitting index out of bounds when jumping.
 *
 * @param nrOfPaginationNumbers The number of pagination buttons to display at the same time.
 * Used to double check so we do not jump out of bounds when clicking the three dots.
 * @param jumpBackwards Denotes whether or not the three dots should redirect the user backwards in the pagination
 */

export default function PaginationCollapseButton({
  nrOfPaginationNumbers,
  jumpBackwards = false,
}: PaginationCollapseButtonProps) {
  const { state, changePage } = useContext(PaginationContext);

  const onCollapseButtonClick = () => {
    if (jumpBackwards) {
      return 0 < state.currentPage - nrOfPaginationNumbers
        ? changePage(state.currentPage - nrOfPaginationNumbers)
        : changePage(0);
    }

    return state.currentPage + nrOfPaginationNumbers < state.numberOfPages
      ? changePage(state.currentPage + nrOfPaginationNumbers)
      : changePage(state.numberOfPages - 1);
  };

  return (
    <span
      className='text-lg hover:cursor-pointer'
      onClick={onCollapseButtonClick}
    >
      ...
    </span>
  );
}
