import { useContext, useEffect } from 'react';

import { PageName } from 'src/typesGlobal';
import { RegisterSubLinkContext } from './NavLink';

interface HiddenSubNavLinkProps {
  href: PageName;
}

/**
 * This creates a link for a subpage without a clickable element.
 * The text color of the parent NavLink turns blue when active.
 *
 * @param href the url to the page we want to affect the parent NavLink
 */

export function HiddenSubNavLink({ href }: HiddenSubNavLinkProps) {
  const { registerNewSubLink } = useContext(RegisterSubLinkContext);

  useEffect(() => {
    registerNewSubLink(href);
  }, [href, registerNewSubLink]);

  return null;
}
