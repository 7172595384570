import {
  ArrowRight,
  Calendar,
  Fork,
  Gym,
  Home,
  PhoneMockOutline,
  Taggisar,
  countdownClock,
  defaultBackground,
} from 'ck-assets';

import { PhoneMockConfig } from 'ck-queries';
import { ReactSVG } from 'react-svg';
import { useMemo } from 'react';

interface Props extends PhoneMockConfig {
  isCountdown?: boolean;
}

// NOTE: This component is quite messy.

// Renders the phone mock with the given configuration
export const PhoneMockBase = ({
  title,
  themeLinkButtonText,
  theme,
  pngImage,
  countdownButtonEnabled,
  isCountdown,
}: Props) => {
  const backgroundSVG = useMemo(() => {
    if (typeof pngImage === 'string') {
      return pngImage;
    }

    // If we don't check for pngImage.size an error occurs
    // were createObjectURL fails, and subsecuently shows the user the 404 page
    return pngImage && pngImage.size
      ? URL.createObjectURL(pngImage)
      : defaultBackground;
  }, [pngImage]);

  // If this is the countdown view, set to default colors
  const backgroundColor = isCountdown
    ? '#222D34'
    : theme?.palette.layout.background;
  const homeThemeColor = isCountdown
    ? '#00ACFF'
    : theme?.palette.section.home.main;
  const backgroundSecondary = isCountdown
    ? '#222D34'
    : theme?.palette.layout.backgroundSecondary;

  return (
    <div>
      <ReactSVG src={PhoneMockOutline} className='absolute z-50' />
      <div
        style={{ backgroundColor: backgroundColor }}
        className='ml-1 h-[670px] w-[338px] overflow-hidden rounded-[55px] py-3'
      >
        {/* Top container */}
        <div className='relative flex h-1/2 flex-col items-center justify-start overflow-hidden border-blue-500'>
          {/* BACKGROUND */}
          <img
            src={isCountdown ? defaultBackground : backgroundSVG}
            className='h-[101.5%] w-full'
          />
          {themeLinkButtonText && !isCountdown && (
            <div
              style={{
                backgroundColor: theme?.palette.section.home.themeLinkButton,
              }}
              className='absolute bottom-14 flex items-center gap-2 rounded-xl py-3 px-5 text-white '
            >
              {themeLinkButtonText}
              <ReactSVG src={ArrowRight} className={'h-4 w-4'} />
            </div>
          )}
          <ReactSVG
            src={Taggisar}
            className={'absolute bottom-0 z-20 -mb-1'}
            style={{ fill: backgroundColor }}
          />
          {isCountdown && (
            <div className='absolute flex h-full justify-center'>
              <div className='flex flex-col items-center justify-center self-center rounded-lg bg-[#242D33] p-3 text-white'>
                <p className='pb-2 text-sm'>
                  {title ? title : '<Temats namn>'}
                </p>
                <img src={countdownClock} className='w-40' />
                {countdownButtonEnabled && (
                  <p className='mb-1 text-xs font-bold text-[#53A8F9]'>
                    Read more
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        {/* Bottom container */}
        <div className='flex h-1/2 w-full flex-col items-center justify-between'>
          <div className='flex w-full flex-col items-center px-10'>
            <div className='z-10 mb-5 self-start text-white'>Hi User,</div>
            <div className='mb-2 text-[10px] font-light text-gray-300 '>
              YOUR STUDENT UNION CARD BALANCE
            </div>
            <div className='mb-5 text-2xl font-bold text-white '>200 KR</div>
            <div
              className={
                'flex w-24 items-center justify-center rounded-xl px-2  py-[11px]'
              }
              style={{ backgroundColor: homeThemeColor }}
            >
              <div className='text-xs text-white'>Top up +</div>
            </div>
          </div>
          {/* NAVBAR */}
          <div
            className={
              'mb-10 flex flex-row gap-8  rounded-2xl px-8 pt-4 pb-4 shadow-md'
            }
            style={{
              backgroundColor: backgroundSecondary,
            }}
          >
            <div className='flex flex-col items-center'>
              <ReactSVG src={Home} style={{ fill: homeThemeColor }} />
              <div
                className='h-2 text-[0.6rem] text-white'
                style={{ color: homeThemeColor }}
              >
                home
              </div>
            </div>
            <ReactSVG src={Fork} className='fill-white' />
            <ReactSVG src={Calendar} className='fill-white' />
            <ReactSVG src={Gym} className='fill-white' />
          </div>
        </div>
      </div>
    </div>
  );
};
