import { PhoneMockConfig, ThemeFormData, ThemeSubset } from 'ck-queries';

import dayjs from 'dayjs';

export const defaultThemeColors: ThemeSubset = {
  palette: {
    layout: {
      background: '#222D34',
      backgroundSecondary: '#222D34',
      paper: '#374750',
    },
    section: {
      home: {
        main: '#00ACFF',
        low_constrast: '#3377AA',
        themeLinkButton: '#00ACFF',
      },
      events: {
        main: '#F86600',
        topGraphic: {
          leftPart: '#27ad72',
          middlePart: '#f86600',
          rightPart: '#843690',
        },
      },
      restaurants: {
        main: '#27AD72',
        topGraphic: {
          leftPart: '#27AD72',
          middlePart: '#D8004D',
          rightPart: '#F8686D',
        },
      },
      workout: {
        main: '#7CCDC2',
        low_constrast: '#66AEAA',
      },
    },
    primary: {
      main: '#69C593',
      icon: '#06CC9B',
    },
    secondary: {
      main: '#00ACFF',
    },
  },
};

const endOfCountdownDefault = dayjs()
  .add(1, 'week')
  .set('hour', 8)
  .set('minutes', 0)
  .format('YYYY-MM-DD hh:mm');

const endDefault = dayjs()
  .add(2, 'week')
  .set('hour', 8)
  .set('minutes', 0)
  .format('YYYY-MM-DD hh:mm');

export const defaultThemeValues: Omit<ThemeFormData, 'targetGroup'> = {
  userName: '',
  image: null,
  countdownButtonEventId: '',
  themeButtonEventId: '',
  countdownEnabled: 'disabled',
  title: '',
  themeLinkUrl: '',
  themeLinkButtonText: '',
  countdownLinkUrl: '',
  start: endOfCountdownDefault,
  end: endDefault,
  countdownButtonType: 'none',
  themeLinkButtonType: 'none',
  status: 'upcoming',
  theme: defaultThemeColors,
};

export const defaultPhoneMockConfig: PhoneMockConfig = {
  title: '',
  pngImage: null,
  theme: defaultThemeColors,
  hasCountdown: false,
  themeLinkButtonText: '',
  countdownButtonEnabled: false,
};
