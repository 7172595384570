import {
  Bell,
  Brush,
  CogWheel,
  EventCalendar,
  Poll,
  Profile,
  UserGroup,
  chalmersLogo,
} from 'ck-assets';
import { ClickableText, NavLink, SubNavLink } from 'ck-components';
import { roleDisplayNameMap, useAuth } from 'ck-queries';

import { HiddenSubNavLink } from 'src/components/NavLinks/HiddenSubNavLink';
import { ReactSVG } from 'react-svg';
import { isPageAllowed } from 'ck-utils';
import { useNavigate } from 'react-router-dom';

/**
 * The navigation bar which is shown on the left-hand side of the screen. Contains all the links used to navigate the app. Conditionally renders the links based on the user's authorized functions.
 *
 */

export function Navbar() {
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  // TODO: Find better way to determine if we have a user
  if (!user) {
    return <></>;
  }

  const isDivisionAdmin = user?.roles.includes('DivisionAdmin');
  const isSuperAdmin = user?.roles.includes('SuperAdmin');

  // TODO: Refactor to handle several committees when BE is ready
  const role = user?.roles[0];
  const divisionName = user?.divisions?.find((d) => d.id)?.name;
  const committeeName = user?.committees?.find((c) => c.id)?.name;

  // TODO: Refactor to handle several committees
  const divisionId = user?.divisions?.find((c) => c.id)?.id;

  return (
    <div className='fixed flex h-full min-h-screen min-w-[305px] flex-col items-center  overflow-scroll bg-white drop-shadow-md'>
      <div className='flex justify-center px-8 pb-14 pt-14'>
        <img
          src={chalmersLogo}
          alt='Chalmers Student Union Logo'
          className='h-24 w-52'
        />
      </div>
      <div className='flex h-full w-full flex-col justify-between'>
        <div className='flex w-full flex-col justify-center gap-3'>
          {/* Conditional rendering of pages based on authorized functions */}
          {/* TODO: These allowance checks should be part of the NavLink component */}
          {isPageAllowed(user, '/push-notifications/create') && (
            <NavLink
              href='/push-notifications/create'
              label='Pushnotiser'
              icon={Bell}
            >
              <SubNavLink
                href='/push-notifications/create'
                label='Skapa pushnotis'
              />
              <HiddenSubNavLink href='/push-notifications/copy/' />
              <HiddenSubNavLink href='/push-notifications/edit/' />
              <SubNavLink href='/push-notifications/archive' label='Arkiv' />
            </NavLink>
          )}
          {isPageAllowed(user, '/themes/create') && (
            <NavLink href='/themes/create' label='Teman' icon={Brush}>
              <SubNavLink href='/themes/create' label='Skapa tema' />
              <SubNavLink href='/themes/archive' label='Arkiv' />
              <HiddenSubNavLink href='/themes/copy/' />
              <HiddenSubNavLink href='/themes/edit/' />
            </NavLink>
          )}
          {isPageAllowed(user, '/events/create') && (
            <NavLink href='/events/create' label='Event' icon={EventCalendar}>
              <SubNavLink href='/events/create' label='Skapa event' />
              <SubNavLink href='/events/archive' label='Arkiv' />
              {isSuperAdmin && (
                <SubNavLink href='/events/swish' label='Swishkassa' />
              )}
              <HiddenSubNavLink href='/events/copy/' />
              <HiddenSubNavLink href='/events/edit/' />
            </NavLink>
          )}
          {isPageAllowed(user, '/polls/create') && (
            <NavLink href='/polls/create' label='Omröstning' icon={Poll}>
              <SubNavLink href='/polls/create' label='Skapa omröstning' />
              <SubNavLink href='/polls/archive' label='Arkiv' />
              <HiddenSubNavLink href='/polls/edit/' />
              <HiddenSubNavLink href='/polls/copy/' />
            </NavLink>
          )}
          {isSuperAdmin && (
            <NavLink
              href='/organisations'
              label='Organisationer'
              icon={UserGroup}
            ></NavLink>
          )}
          {isDivisionAdmin && (
            <NavLink
              href={`/organisations/${divisionId}`}
              label='Organisationer'
              icon={UserGroup}
            ></NavLink>
          )}
          <NavLink href='/settings' label='Inställningar' icon={CogWheel} />
        </div>
        <div className='flex flex-col items-center justify-center'>
          <div
            className='mb-10 flex cursor-pointer flex-col items-center'
            onClick={() => navigate('/settings')}
          >
            <ReactSVG src={Profile} className='h-14 w-14' />
            <div className='text-lg'>
              {role ? roleDisplayNameMap[role] : ''}
            </div>
            {divisionName && !committeeName && (
              <div className='text-sm'>{divisionName}</div>
            )}
            {committeeName && <div className='text-sm'>{committeeName}</div>}
          </div>
          <ClickableText
            label='Logga ut'
            onPress={logout}
            customStyle='mb-24 text-[#D8004D] active:text-[#A3003A] font-bold text-xl'
          />
        </div>
      </div>
    </div>
  );
}
