import { QK } from '../queryKeys';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

type TargetGroupsResponse = {
  targetGroups: Array<TargetGroup>;
};

export type TargetGroup = {
  group: string;
  count: string;
};

// Query hook
export const useTargetGroups = () =>
  useQuery({
    queryKey: QK.targetGroups,
    queryFn: getTargetGroups,
  });

// Query function
const getTargetGroups = () =>
  fetchWithErrorThrowing('/target-groups', {
    method: 'GET',
  })
    .then((res) => res.json())
    .then(({ targetGroups }: TargetGroupsResponse) => targetGroups);
