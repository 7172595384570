import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { isPageAllowed, useTypedLocation } from 'ck-utils';

import { Navbar } from 'ck-widgets';
import { useAuth } from 'ck-queries';
import { useEffect } from 'react';

/**
 * This is the Root component that wraps all authenticated routes to provide styling and a Navbar.
 * The <Outlet /> is where all child routes to '/' will be rendered.
 * To see all routes go to src/index.tsx
 * @returns Root page wrapper component
 */
export default function Root() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useTypedLocation();

  // Redirect if we don't have a user
  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  // TODO: Add FullPageSpinner
  if (!user) {
    return <></>;
  }

  // Only render this if the user has the correct function
  return (
    <div className='flex min-h-screen w-full flex-row'>
      <div className='relative h-screen min-w-[305px]'>
        <Navbar />
      </div>
      <div className='flex w-full bg-[#FCFCFC] px-10 py-20'>
        {isPageAllowed(user, location.pathname) ? (
          <Outlet />
        ) : (
          <Navigate to='/' />
        )}
      </div>
    </div>
  );
}
