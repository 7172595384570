import { removeNullUndefinedProperties } from './removeNullUndefinedProperties';

/**
 * A utility function that creates a FormData object from the given data object.
 * @param {T} data - An object of type T which extends Record<string, any>
 * @returns {FormData} - A FormData object containing key-value pairs from the input data object
 */
export function createFormData<T extends Record<string, any>>(
  rawData: T
): FormData {
  const formData = new FormData();

  const data = removeNullUndefinedProperties(rawData);

  const appendFormData = (obj: Record<string, any>, prefix?: string) => {
    for (const key in obj) {
      if (key === 'image') {
        return;
      }
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        const newKey = prefix ? `${prefix}[${key}]` : key;
        if (value instanceof File) {
          formData.append(newKey, value);
        }
        if (typeof value === 'string' || typeof value === 'number') {
          formData.append(newKey, String(value));
        } else if (value === null || value === undefined) {
          formData.append(newKey, '');
        } else if (typeof value === 'object') {
          appendFormData(value, newKey);
        } else {
          appendFormData(value, newKey);
        }
      }
    }
  };

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key];
      if (Array.isArray(value)) {
        value.forEach((item: any) => {
          if (typeof item === 'object') {
            appendFormData(item, key);
          } else {
            formData.append(key, String(item));
          }
        });
      } else if (typeof value === 'object') {
        if (key === 'image') {
          formData.append(key, value);
        } else {
          appendFormData(value, key);
        }
      } else {
        formData.append(key, value);
      }
    }
  }

  return formData;
}
