import { Button, MainTitle } from 'ck-components';
import { QK, SwishStore, deleteSwishAccount, getSwishStores } from 'ck-queries';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { ArchiveTab } from 'src/widgets';
import { CreateSwishModal } from 'src/components/Modals/CreateSwishModal/CreateSwishModal';
import { Plus } from 'ck-assets';
import { swishTableStructure } from './types';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useSwishStores } from 'src/queries/swish/useSwishStores';

/**
 * Swish page
 *
 */
export function EventSwishPage() {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [rowData, setRowData] = useState<SwishStore | undefined>();
  const [isEdit, setIsEdit] = useState(false);
  const Q_swishAccounts = useSwishStores();

  // TODO: Swap to general loading spinner
  if (Q_swishAccounts.isLoading) {
    return <></>;
  }

  const deleteEventMutationConfig: UseMutationOptions<
    Response,
    unknown,
    string
  > = {
    mutationFn: (idToDelete: string) =>
      toast.promise(
        deleteSwishAccount(idToDelete),
        {
          loading: 'Laddar...',
          success: 'Swishkassan har tagits bort',
          error: 'Kunde inte ta bort swishkassan',
        },
        {
          duration: 8000,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(QK.swishStores);
    },
  };

  const onEditClick = (data: SwishStore) => {
    if (data.swishStoreId) {
      setRowData(data);
      setIsEdit(true);
      setIsOpen(true);
    } else {
      toast.error('Kunde inte hitta eventet. Prova uppdatera sidan.');
    }
  };

  const onCreateClick = () => {
    setIsOpen(true);
    setIsEdit(false);
    setRowData(undefined);
  };

  return (
    <div className='flex flex-1 flex-col'>
      <div className='flex items-start pb-8'>
        <MainTitle label={'Swishkassa'} />
        <Button
          customStyle='whitespace-nowrap text-lg h-16'
          iconLeft={Plus}
          label={'Ny kassa'}
          onClick={onCreateClick}
        />
      </div>

      <CreateSwishModal
        isEdit={isEdit}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={rowData}
      />

      <ArchiveTab
        emptyStateMessage='Det finns inga swishkassor.'
        queryConfig={{
          queryKey: QK.swishStores,
          queryFn: getSwishStores,
        }}
        tableStructure={swishTableStructure}
        mutationConfig={deleteEventMutationConfig}
        onEditClick={onEditClick}
      />
    </div>
  );
}
