import { ReactSVG } from 'react-svg';
import { Spinner } from 'src/assets';
import clsx from 'clsx';
interface Props {
  width?: number;
  className?: string;
}

/**
 *This loading spinner will expand to the full size of it's parent if property "width" is undefined.
 *
 * @param width the width of the loading spinner
 * @param className additional custom styling
 */
export function LoadingSpinner({ width, className }: Props) {
  const spinnerWidth = width ? `width: ${width}px` : 'width: 100%';
  return (
    <div className='flex items-center justify-center'>
      <ReactSVG
        src={Spinner}
        className={clsx(
          'animate-spin fill-sky-500 text-gray-200 dark:text-gray-200',
          className
        )}
        beforeInjection={(svg) => {
          svg.setAttribute('style', spinnerWidth);
        }}
      />
    </div>
  );
}
