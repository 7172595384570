import { EventObj, PostCreateEventV1 } from './types';

import { UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import { standardDateFormat } from 'ck-utils';

export const formatEvents = (events: EventObj[]) =>
  events.map((el) => ({
    ...el,
    startTime: dayjs(el.startTime).format(standardDateFormat),
    endTime: dayjs(el.endTime).format(standardDateFormat),
    updatedAt: dayjs(el.updatedAt).format(standardDateFormat),
    createdBy: el.createdBy ? el.createdBy : 'Tidigare formulär',
  }));

export const removeReservationDetailsValues = (
  form: UseFormReturn<PostCreateEventV1, any>
) => {
  const { setValue, watch } = form;
  const reservationType = watch('eventReservationDetails.reservationType');

  if (reservationType === 'Open') {
    setValue('eventReservationDetails.tickets', undefined);
    setValue('eventReservationDetails.ticketReleaseTime', undefined);
    setValue('eventReservationDetails.ticketReservationExpiryTime', undefined);
    setValue('eventReservationDetails.gatherFoodPreferences', undefined);
  }
};
