import { ChevronDown, ChevronUp } from 'ck-assets';
import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';

import { ErrorMessages } from '../errormessages/ErrorMessages';
import { FormLabelNew } from '../FormLabel/FormLabelNew';
import { ListOptionsWithSearch } from './ListOptionsWithSearch';
import { Listbox } from '@headlessui/react';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

interface Props<T extends FieldValues> {
  parentForm: UseFormReturn<T>;
  name: Path<T>;
  formLabel: string;
  description?: string;
  optionsData: PathValue<T, Path<T>>[];
}

export const ListboxWithSearch = <T extends FieldValues>({
  parentForm,
  name,
  formLabel,
  description,
  optionsData,
}: Props<T>) => {
  const { watch, setValue, formState } = parentForm;
  const selectedOptions = watch(name);
  return (
    <div className={clsx('relative w-full')}>
      <Listbox
        value={selectedOptions}
        multiple
        onChange={(selected) => setValue(name, selected)}
        name={name}
      >
        {({ open }) => {
          return (
            <>
              <FormLabelNew
                label={formLabel}
                description={description}
                name={name}
                parentForm={parentForm}
              />

              <Listbox.Button
                id={name}
                className={clsx(
                  'mb-0 flex h-14 w-full items-center justify-between rounded border border-gray-300 bg-white px-4 text-left focus:outline-sky-500'
                )}
              >
                {selectedOptions?.length > 0 ? (
                  selectedOptions.join(', ')
                ) : (
                  <span className='text-gray-400'>{`Välj ${formLabel.toLowerCase()}`}</span>
                )}
                <ReactSVG
                  src={open ? ChevronUp : ChevronDown}
                  className='w-6'
                />
              </Listbox.Button>
              <ListOptionsWithSearch
                parentForm={parentForm}
                name={name}
                formLabel={name}
                description={description}
                optionsData={optionsData}
              />
              <ErrorMessages
                errors={formState.errors}
                name={name}
                takeUpSpace={true}
              />
            </>
          );
        }}
      </Listbox>
    </div>
  );
};
