import { GetSwishResV1 } from './types';
import { QK } from '../queryKeys';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useSwishStores = () =>
  useQuery({
    queryKey: QK.swishStores,
    queryFn: getSwishStores,
  });

// Query function
export async function getSwishStores(): Promise<GetSwishResV1> {
  const res = await fetchWithErrorThrowing('/swish-stores', {
    method: 'GET',
  });

  const data = await res.json();

  return data.swishStores;
}
