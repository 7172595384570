import {
  Form,
  FormTextInput,
  InformationModal,
  ModalColumn,
  SingleSelectDropdown,
} from 'ck-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SwishStore, useEventsHosts, useSwishStoreCreate } from 'ck-queries';

import { useEffect } from 'react';

interface Props {
  isOpen: boolean;
  isEdit?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  data?: SwishStore;
}

export const CreateSwishModal = ({
  isOpen,
  setIsOpen,
  data: initialData,
  isEdit,
}: Props) => {
  const form = useForm<SwishStore>();
  const M_createSwishStore = useSwishStoreCreate();
  const Q_hosts = useEventsHosts(!isEdit);

  const { handleSubmit, trigger, reset, setValue } = form;

  const onSubmit: SubmitHandler<SwishStore> = (data) => {
    if (data)
      M_createSwishStore.mutateAsync(data).then(() => {
        setIsOpen(false);
        window.location.reload();
      });
  };

  useEffect(() => {
    if (initialData && isEdit) {
      reset({
        committeeId: initialData.committeeId,
        committee: initialData.committee,
        swishStore: initialData.swishStore,
        swishStoreId: initialData.swishStoreId,
      });
    } else {
      reset({
        committeeId: '',
        committee: '',
        swishStore: '',
        swishStoreId: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, isEdit]);

  return (
    <InformationModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customStyle='md:max-w-[600px] bg-red-500'
      showCloseButton={false}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        buttonType='button'
        submitButtonLabel={'Lägg till'}
        submitButtonStyle='w-52'
        trigger={trigger}
        isSubmitting={false}
        showCancelButton
        centerButtons
        onCancel={() => setIsOpen(false)}
      >
        <ModalColumn
          ignoreGap
          title={isEdit ? 'Redigera swishkassa' : 'Lägg till swishkassa'}
        >
          {!isEdit && (
            <>
              <p>
                Lägg till en Swishkassa för en kommitté. Kassan kommer att kunna
                användas när du skapar event.
              </p>
              <div className='h-6' />
            </>
          )}

          {Q_hosts.data && (
            <div className='z-100'>
              <SingleSelectDropdown
                label='Kommitté'
                name='committeeId'
                onChange={(e) => {
                  setValue(
                    'committeeId',
                    Q_hosts.data?.find((h) => h.name === e)?.id ?? ''
                  );
                  setValue('committee', e ?? '');
                }}
                parentForm={form}
                optionsData={Q_hosts.data.map((host) => host.name)}
                useOtherValues={Q_hosts.data.map((host) => host.id)}
                disabled={Q_hosts.data.length === 0 || isEdit}
                isShorter
              />
            </div>
          )}
          <FormTextInput
            label='Swishnummer till kommitté'
            name='swishStore'
            parentForm={form}
            options={{
              required: true,
              onChange: () => trigger('swishStore'),
              validate: {
                isNumbers: (input: string) =>
                  /^\d+$/.test(input) || 'Endast siffror',
              },
            }}
          />
        </ModalColumn>
      </Form>
    </InformationModal>
  );
};
