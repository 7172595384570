import { QK } from '../queryKeys';
import { fetchWithErrorThrowing } from '../../utils/fetchWithErrorThrowing';
import { useQuery } from '@tanstack/react-query';
import { EventIdName } from './types';

// Query hook
export const useEventsCategories = () => {
  return useQuery({
    queryKey: QK.events.categories,
    queryFn: getEventsCategories,
  });
};

// Query function
async function getEventsCategories(): Promise<EventIdName[]> {
  const res = await fetchWithErrorThrowing('/events/categories', {
    method: 'GET',
  });
  const data: { eventCategories: Array<{ id: string; name: string }> } =
    await res.json();

  return data.eventCategories;
}
