// This files contains functions that returns error options for react-hook-form

import { capitalizeString } from 'src/utils';

export const getMaxLengthOption = (
  length: number | undefined,
  name: string | undefined
) => {
  // Nullcheck
  if (!length || !name) return;

  return {
    value: length,
    message: `${capitalizeString(name)} får max vara ${length} tecken`,
  };
};

export const getMinLengthOption = (
  length: number | undefined,
  name: string | undefined
) => {
  // Nullcheck
  if (!length || !name) return;

  return {
    value: length,
    message: `${capitalizeString(name)} måste vara minst ${length} tecken`,
  };
};

export const getRequiredOption = (optional: boolean | undefined) => {
  return optional ? undefined : 'Detta fält får inte lämnas tomt';
};
