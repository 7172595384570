import React from 'react';
import clsx from 'clsx';

interface ContentCardProps {
  children: React.ReactNode;
  customStyle?: string;
}

/**
 * A custom component wrapper which renders the children within a white box
 * with rounded corners.
 *
 * @param children the child components to be rendered within the area
 * @param customStyle optional additional styling for the wrapper
 */

export function ContentCard({ children, customStyle }: ContentCardProps) {
  return (
    <div
      className={clsx(
        'flex h-fit w-fit rounded-2xl border border-neutral-200 bg-white p-8',
        customStyle
      )}
    >
      {children}
    </div>
  );
}
