import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';

import { useEffect } from 'react';

/**
 * @typedef {object} Props
 * @template T
 * @property {UseFormReturn<T>} form - A useForm return object.
 * @property {Path<T>} name - The name of the hidden input field.
 * @property {Path<T>} mirror - The name of the mirrored field.
 */
export interface Props<T extends FieldValues> {
  form: UseFormReturn<T>;
  name: Path<T>;
  mirror: Path<T>;
  beforeInsertion?: (value: string) => PathValue<T, Path<T>>;
}

/**
 * A hidden input component that mirrors the value of another input field.
 *
 * @function HiddenInput
 * @template T
 * @param {Props<T>} props - The props for the hidden input component.
 * @returns {JSX.Element} A hidden input field that mirrors the value of another input field.
 */
export const HiddenInput = <T extends FieldValues>({
  name,
  form,
  mirror,
  beforeInsertion,
}: Props<T>) => {
  const { register, watch, setValue } = form;
  const mirrorValue = watch(mirror);

  // Watch the mirrored field and update the hidden input field when it changes
  useEffect(() => {
    if (beforeInsertion) {
      setValue(name, beforeInsertion(mirrorValue));
    } else {
      setValue(name, mirrorValue);
    }
  }, [mirrorValue, setValue, name, beforeInsertion]);

  return <input type='hidden' {...register(name)} />;
};
