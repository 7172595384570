import { PostCreateEventV1 } from 'ck-queries';
import dayjs from 'dayjs';

// Set to 08:00 tomorrow
const defaultDate = dayjs().add(1, 'day').set('h', 8).set('m', 0);

// Set to 09:00 tomorrow
const defaultEndTime = defaultDate.add(1, 'h');

export const defaultEventValues: PostCreateEventV1 = {
  heldInLanguage: 'English',
  targetUserType: 'Students',
  date: defaultDate.format('YYYY-MM-DD'),
  startTime: defaultDate.format('HH:mm'),
  endTime: defaultEndTime.format('HH:mm'),
  divisions: [],
  host: '',
  categories: [],
  description: '',
  descriptionSwedish: '',
  location: '',
  locationSwedish: '',
  name: '',
  nameSwedish: '',
  eventLink: '',
  image: null,
  imageCred: '',
  linkButtonText: '',
  linkButtonTextSwedish: '',
  isDraft: false,
  eventReservationDetails: {
    reservationType: 'Open',
    ticketReleaseTime: defaultDate.format('YYYY-MM-DDTHH:mm'),
    ticketReservationExpiryTime: defaultEndTime.format('YYYY-MM-DDTHH:mm'),
    gatherFoodPreferences: 'true',
    swishStore: '',
    tickets: [{ swedishName: '', englishName: '', price: 0 }],
    numberOfTickets: undefined,
  },
};
