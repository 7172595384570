import { User, getDivisions } from 'src/queries';

import { useAuth } from '../auth/useAuth';
import { useQuery } from '@tanstack/react-query';

// Custom hook to get the user's division based on their permissions and the division ID from the URL params
export const useUserDivision = (divisionIdFromParams: string) => {
  const { user } = useAuth();
  return useQuery(['division', divisionIdFromParams], () =>
    getSpecificDivision(divisionIdFromParams, user)
  );
};

// Function to fetch user's division based on their permissions and the division ID from the URL params
async function getSpecificDivision(
  divisionIdFromParams: string,
  user: User | null
) {
  // Check if user is a SuperAdmin based on their roles
  if (user?.roles.includes('SuperAdmin')) {
    return (
      // Fetch all divisions and return the one with a matching ID
      (await getDivisions()).find(({ id }) => id === divisionIdFromParams) ||
      undefined
    );
  } else {
    // Return user's first assigned division (or undefined if they have none)
    return user?.divisions[0] || undefined;
  }
}
