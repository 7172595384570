import { fetchWithErrorThrowing } from 'ck-utils';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

type ValidateResponse = { isValid: boolean; email: string };

export const groupToUserMap = {
  divisions: 'admin',
  committees: 'moderator',
} as const;

export type GroupType = keyof typeof groupToUserMap;
export type UserType = (typeof groupToUserMap)[GroupType];

export const useValidateInviteToken = (groupType: GroupType) => {
  const navigate = useNavigate();

  return useMutation({
    mutationKey: [groupType, 'invite-token'],
    mutationFn: (data: { token?: string }) =>
      toast.promise(
        postValidateInviteToken(data, groupType),
        {
          loading: 'Saving...',
          success: 'Länk ok!',
          error: 'Länken har antingen gått ut eller är ogiltig. Försök igen.',
        },
        {
          duration: 8000,
        }
      ),
    onSuccess: (data) => {
      // If the token is invalid, show an error message and redirect to the login page.
      if (!data.isValid) {
        toast.error(
          'Länken har antingen gått ut eller är ogiltig. Försök igen.'
        );
        navigate('/login');
      }
    },
  });
};

async function postValidateInviteToken(
  data: { token?: string },
  group: GroupType
): Promise<ValidateResponse> {
  const res = await fetchWithErrorThrowing(`/${group}/validate-token`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({ 'content-type': 'application/json' }),
  });

  const result: ValidateResponse = await res.json();

  return result;
}
