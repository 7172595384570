import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { FormLabelNew } from './FormLabel/FormLabelNew';
import { ReactSVG } from 'react-svg';
import { Upload } from 'src/assets';
import clsx from 'clsx';

interface FormInputProps<T extends FieldValues> {
  name: Path<T>;
  parentForm: UseFormReturn<T>;
  accept: React.InputHTMLAttributes<HTMLInputElement>['accept'];
  emptyState?: string;
  description?: string;
  label?: string;
}

export const UploadInput = <T extends FieldValues>({
  name,
  parentForm: form,
  description,
  accept,
  emptyState,
  label,
}: FormInputProps<T>) => {
  const { register, watch, control } = form;
  const value = watch(name);

  const maxUploadSize = 'Max 4mb.';

  return (
    <label htmlFor='file-input' className=' z-0 mb-6'>
      {label && (
        <FormLabelNew
          name={name}
          label={label}
          description={`${description} ${maxUploadSize}`}
        />
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <input
              {...field}
              value={value?.fileName}
              onChange={(e) => {
                // Nullcheck
                if (!e.target.files) return;
                const file = e.target.files[0];
                const fourMegaBytes = 4000000;

                if (file?.size >= fourMegaBytes) {
                  alert(`För stor bild. ${maxUploadSize}`);
                  return;
                }

                const filenameWithoutEnding = file.name.substring(
                  0,
                  file.name.lastIndexOf('.')
                );

                if (filenameWithoutEnding.includes('.')) {
                  alert('Filnamnet får inte innehålla punkter.');
                  return;
                }

                onChange(file);
              }}
              accept={accept}
              className=' absolute h-0 w-0 opacity-0'
              type='file'
              id='file-input'
            />
          );
        }}
      />
      <input
        {...register(name)}
        type='file'
        multiple={false}
        accept={accept}
        className='absolute h-0 w-0 opacity-0'
        id='file-input'
      />
      <div
        className={clsx(
          'text-md mb-6 flex items-center justify-between rounded border border-gray-300 bg-white py-4 px-4 font-light text-gray-400 hover:cursor-pointer hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 active:bg-white',
          value?.name && 'text-black'
        )}
      >
        {value?.name || emptyState || 'Välj fil'}
        <ReactSVG src={Upload} className='ml-2 h-6 w-6 text-black' />
      </div>
    </label>
  );
};
