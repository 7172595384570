import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QK } from '../queryKeys';
import { fetchWithErrorThrowing } from '../../utils/fetchWithErrorThrowing';
import { toast } from 'react-hot-toast';

// Mutation hook
export const useDeleteAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) =>
      toast.promise(
        deleteAdmin(id),
        {
          loading: 'Laddar...',
          success: `Admin borttagen`,
          error: 'Kunde inte ta bort Admin',
        },
        {
          duration: 8000,
        }
      ),
    onSettled: () => {
      queryClient.invalidateQueries([QK.admins]);
    },
  });
};

// Mutation function
const deleteAdmin = (id: string) =>
  fetchWithErrorThrowing(`/divisions/admins/${id}`, {
    method: 'DELETE',
    headers: new Headers({ 'content-type': 'application/json' }),
  });
