export const fetchImageFromRemoteBlobStorage = async (
  url: string | undefined
) => {
  // Nullcheck
  if (!url) return null;

  try {
    const response = await fetch(url);
    const imageName = response.url.split('/').pop();
    const blob = await response.blob();
    const file = new File([blob], imageName || '', {
      type: blob.type,
    });
    return file;
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
};
