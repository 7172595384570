import RowComponent from './RowComponent';
import clsx from 'clsx';

export type ArrElement<T> = T extends (infer ElementType)[]
  ? ElementType
  : never;

export interface TableProps<T> {
  tableColHeadings: string[];
  tableRowDataPaths: Array<keyof ArrElement<T>>;
  data: T;
  onRowPress?: (rowData: any) => void;
  customStyleHeaders?: string;
  onDeletePress?: (data: any) => void;
  onEditPress?: (data: any) => void;
  onCopyPress?: (data: any) => void;
  onDownloadPress?: (data: any) => void;
  onCustomPress?: (data: any) => void;
  customLastColumn?: React.ReactNode;
}

/**
 * Our styled and customized dynamic data table. Accepts a data object of any size and displays it
 * in accordance with the design mock.
 *
 * @param tableColHeadings an array of strings which will be rendered as table headings
 * @param tableRowDataPaths an array of strings containing the paths which the data will map against
 * @param data the data object to be rendered within the table
 * @param onRowPress an optional callback which is called when a row has been pressed
 * @param onDeletePress an optional callback with is called when the trash icon is pressed
 * @param onCopyPress an optional callback with is called when the copy icon is pressed
 * @param onDownloadPress an optional callback with is called when the copy icon is pressed
 */

export function Table<T extends Array<{ [key in keyof ArrElement<T>]: any }>>({
  tableColHeadings,
  tableRowDataPaths,
  data,
  onRowPress,
  customStyleHeaders,
  onDeletePress,
  onEditPress,
  onCopyPress,
  onDownloadPress,
  customLastColumn,
  onCustomPress,
}: TableProps<T>) {
  // If we have a delete or edit function, add 'ÄNDRA' in the last heading
  const tableHeadings =
    Boolean(onDeletePress) || Boolean(onEditPress)
      ? [...tableColHeadings, 'ÄNDRA']
      : tableColHeadings;

  return (
    <div className='relative w-full'>
      <table className='w-full table-fixed border-separate border-spacing-y-2 text-left'>
        <thead>
          <tr>
            {tableHeadings.map((heading, index) => (
              <th
                key={`${heading}-${index}`}
                scope='col'
                className={clsx(
                  'tracking-gray-800 mb-2 mt-7 h-6 whitespace-nowrap px-4 text-left text-base font-light uppercase text-gray-800 first:pl-7 last:pr-7',
                  customStyleHeaders,
                  Boolean(onDeletePress) && 'last:text-end'
                )}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='text-lg'>
          {data?.map((dataEntry, index) => (
            <RowComponent
              key={`${Object.values(dataEntry)[0]}-${index}`}
              data={dataEntry}
              dataPaths={tableRowDataPaths}
              onRowPress={onRowPress}
              isClickable={onRowPress !== undefined}
              onDeletePress={onDeletePress}
              onEditPress={onEditPress}
              onCopyPress={onCopyPress}
              onDownloadPress={onDownloadPress}
              customLastColumn={customLastColumn}
              onCustomPress={onCustomPress}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
