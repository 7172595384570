import { Form, FormTextInput, PasswordInput } from 'ck-components';
import { SubmitHandler, useForm } from 'react-hook-form';

import { PostLoginRequestV1 } from 'src/queries/auth/types';
import { useAuth } from 'ck-queries';

/**
 * The form which handles the login information.
 *
 */

export function LoginForm() {
  const form = useForm<PostLoginRequestV1>();
  const { handleSubmit, trigger } = form;
  const { M_login } = useAuth();

  const onSubmit: SubmitHandler<PostLoginRequestV1> = (data) =>
    M_login.mutate(data);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      submitButtonLabel='Logga in'
      customFormStyle='gap-2'
      submitButtonStyle='w-full'
      trigger={trigger}
      isSubmitting={M_login.isLoading}
    >
      <FormTextInput
        name='email'
        label='E-postadress'
        parentForm={form}
        errorsAlwaysTakeUpSpace={false}
        options={{
          pattern: {
            value:
              // eslint-disable-next-line no-useless-escape
              /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
            message: 'Det måste vara en giltig e-postadress',
          },
        }}
      />

      <PasswordInput
        name='password'
        label='Lösenord'
        parentForm={form}
        minLength={6}
      />
    </Form>
  );
}
