import dayjs from 'dayjs';

/**
 * Simple date helper to create a dayjs object with a dummy date and specified time to be able to compare times
 *
 * @param time Time in format HH:mm
 * @returns Dayjs object with dummy date and inputted time
 **/

export const dayjsTime = (time: string) =>
  dayjs('2000-01-01')
    .set('h', Number(time.split(':')[0]))
    .set('m', Number(time.split(':')[1]));
