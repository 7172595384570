import { Committee, CommitteeFromServer, QK } from 'ck-queries';

import { fetchWithErrorThrowing } from '../../utils/fetchWithErrorThrowing';
import { formatStatus } from './localUtils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useCommittees = (divisionId: string) => {
  return useQuery({
    queryKey: [QK.committees, divisionId],
    queryFn: () => getCommittees(divisionId),
  });
};

// Query function
async function getCommittees(divisionId: string): Promise<Committee[]> {
  const res = await fetchWithErrorThrowing(`/committees/${divisionId}`, {
    method: 'GET',
  });

  const data: { committees: CommitteeFromServer[] } = await res.json();
  return formatCommittees(data.committees);
}

// Query formatter
const formatCommittees = (committees: CommitteeFromServer[]) => {
  // Sort committees alphabetically by name
  committees.sort((a, b) => a.name.localeCompare(b.name));

  return committees.map((c) => {
    // Format status to include expire date
    const status = formatStatus(c.status, c.expireDate, c.invitedEmail);

    return { ...c, status } as Committee;
  });
};
