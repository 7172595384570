import { InformationModalProps } from '../types';
import Modal from './Modal';

/**
 * This modal is used to display information to the user. No buttons are added to the bottom of the modal.
 * The close button in the top right corner is visible, and we can render whatever information we want in the modal
 * body using the children props.
 *
 * @param modalTitle the main title of the modal, displayed in bold letters at the top
 * @param isOpen denotes whether or not the modal is currently visible
 * @param children the child components rendered within the modal body
 * @param customStyle optional custom styling (can be used to for example set a custom max-width)
 * @param showCloseButton denotes whether or not the X-button in the top right corner of the modal
 * should be displayed
 */

export function InformationModal({
  modalTitle,
  isOpen,
  children,
  customStyle,
  setIsOpen,
  showCloseButton = true,
  onClose,
}: InformationModalProps) {
  return (
    <Modal
      modalTitle={modalTitle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customStyle={customStyle}
      showCloseButton={showCloseButton}
      onClose={onClose}
    >
      <div className='py-4'>{children}</div>
    </Modal>
  );
}
