import { UserType } from './useValidateInviteToken';
import { errorHandler } from '../../utils/errorHandler';
import { fetchWithErrorThrowing } from 'ck-utils';
import toast from 'react-hot-toast';
import { useAuth } from './useAuth';
import { useMutation } from '@tanstack/react-query';

type RegisterRequestData = {
  email: string;
  password: string;
  token: string;
};

// Mutation hook
export const useUserRegister = (userType: UserType) => {
  const { M_login } = useAuth();

  return useMutation({
    mutationKey: ['register', userType],
    mutationFn: (data: RegisterRequestData) =>
      toast.promise(
        postRegisterUser(data, userType),
        {
          loading: 'Laddar...',
          success: `Du är nu registrerad som ${userType}!`,
          error: 'Kunde inte registrera användaren',
        },
        {
          duration: 8000,
        }
      ),
    onSuccess: (_, data) => {
      M_login.mutate({ email: data.email, password: data.password });
    },
    onError: errorHandler,
  });
};

// Mutation function
async function postRegisterUser(data: RegisterRequestData, userType: UserType) {
  const url =
    userType === 'admin'
      ? '/divisions/register-admin'
      : '/committees/register-moderator';

  return await fetchWithErrorThrowing(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
}
