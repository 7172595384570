import { useNavigate, useRouteError } from 'react-router-dom';

import { chalmersLogo } from 'ck-assets';

export function RouterErrorPage() {
  const error = useRouteError() as {
    status: number;
    statusText: string;
    message: string;
  };
  const navigation = useNavigate();

  console.error(error);

  const renderErrorText = () => {
    if (error.status === 404) {
      return <div className='text-xl'>404 - Sidan kunde inte hittas</div>;
    }
    // Add additional router error texts here.

    // Generic error text if nothing else matches
    return (
      <div className='text-xl'>
        {error?.status && `${String(error.status)} -`} Något gick fel
      </div>
    );
  };

  return (
    <div className='flex min-h-screen w-screen flex-col items-center justify-center gap-10'>
      <button onClick={() => navigation('/')}>
        <img
          src={chalmersLogo}
          alt='Chalmers Student Union Logo'
          className='h-24 w-80'
        />
      </button>
      {renderErrorText()}
    </div>
  );
}
