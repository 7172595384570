import {
  EventReservationType,
  PostCreateEventV1,
  useEventsHosts,
  useUser,
} from 'ck-queries';
import { FormTextInput, LoadingSpinner, RadioButtons } from 'ck-components';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { TicketForm } from 'src/components/Form/TicketForm/TicketForm';
import dayjs from 'dayjs';
import { useCommitteesSwishStores } from 'src/queries/swish/useCommitteesSwishStores';
import { useEffect } from 'react';

interface Props {
  form: UseFormReturn<PostCreateEventV1>;
  isEdit?: boolean;
}

/**
 * Sub-section of the Create Event form for event type information
 *
 * @param form React Hook Form object for the Create Event form
 * @returns Section of the Create Event form for event type information
 */

export function EventTypeSection({ form, isEdit }: Props) {
  const { trigger, setValue, setError, clearErrors } = form;
  const realeaseDate = useWatch({
    control: form.control,
    name: 'eventReservationDetails.ticketReleaseTime',
  });
  const releaseEndDate = useWatch({
    control: form.control,
    name: 'eventReservationDetails.ticketReservationExpiryTime',
  });
  const startDate = useWatch({ control: form.control, name: 'date' });
  const host = useWatch({ control: form.control, name: 'host' });
  const reservationType = useWatch({
    control: form.control,
    name: 'eventReservationDetails.reservationType',
  });
  const Q_hosts = useEventsHosts(false);

  const selectedHost = Q_hosts.data?.find(
    (committee) => committee.name === host
  );

  const selectedHostId = selectedHost ? selectedHost.id : '';

  const Q_swishStore = useCommitteesSwishStores(
    selectedHostId,
    Boolean(host) && reservationType === 'Paid'
  );

  useEffect(() => {
    setValue(
      'eventReservationDetails.swishStore',
      Q_swishStore.data ? Q_swishStore.data.swishStore : undefined
    );
    if (!Q_swishStore.data?.swishStore) {
      setError('eventReservationDetails.swishStore', {
        message: 'Det finns ingen kassa kopplad till organisatören',
      });
    } else {
      clearErrors('eventReservationDetails.swishStore');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host, Q_swishStore.data]);

  const Q_user = useUser();

  const eventReservationDetailsTypeOptions: Array<{
    label: string;
    value: EventReservationType;
  }> = [
    { label: 'Öppet', value: 'Open' },
    { label: 'Bokningsbart (Gratis)', value: 'Free' },
    { label: 'Bokningsbart (Köp)', value: 'Paid' },
  ];

  const gatherFoodPreferencesOptions: Array<{
    label: string;
    value: boolean;
  }> = [
    { label: 'Ja', value: true },
    { label: 'Nej', value: false },
  ];

  if (Q_user.isLoading || Q_hosts.isLoading) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingSpinner width={100} />
      </div>
    );
  }

  return (
    <>
      <div>
        <RadioButtons
          disabled={isEdit}
          label='Typ av event / Type of event'
          name='eventReservationDetails.reservationType'
          parentForm={form}
          selectOptions={eventReservationDetailsTypeOptions}
        />

        {/* FIRST SECTION */}
        {reservationType !== 'Open' && (
          <div className='w-1/2 flex-col gap-6'>
            <div className='flex gap-6'>
              <div className='flex-1'>
                <FormTextInput
                  label={'Första anmälningsdag'}
                  disabled={isEdit && dayjs(dayjs()).isAfter(realeaseDate)}
                  parentForm={form}
                  name='eventReservationDetails.ticketReleaseTime'
                  minDate={dayjs().format('YYYY-MM-DD')}
                  type='datetime-local'
                  options={{
                    onBlur: () =>
                      trigger(
                        'eventReservationDetails.ticketReservationExpiryTime'
                      ),
                    ...(isEdit
                      ? {}
                      : {
                          validate: {
                            isInFuture: () =>
                              (dayjs(realeaseDate).isAfter(dayjs()) &&
                                dayjs(realeaseDate).isBefore(startDate)) ||
                              'Måste vara i framtiden och innan eventet startar.',
                          },
                        }),
                  }}
                />
              </div>
              <div className='flex-1'>
                <FormTextInput
                  label={'Sista anmälningsdag'}
                  parentForm={form}
                  name='eventReservationDetails.ticketReservationExpiryTime'
                  minDate={dayjs().format('YYYY-MM-DD')}
                  type='datetime-local'
                  options={{
                    onBlur: () => trigger(),
                    validate: {
                      isInFuture: () =>
                        dayjs(releaseEndDate).isAfter(dayjs(realeaseDate)) ||
                        'Måste vara efter biljettsläpp.',
                    },
                  }}
                />
              </div>
            </div>

            {reservationType === 'Paid' && (
              <div>
                <FormTextInput
                  name='eventReservationDetails.swishStore'
                  parentForm={form}
                  disabled
                  placeholder={host ? 'Ingen kassa hittades' : '-'}
                  description='Swishkassa kopplas automatiskt till eventets organisatör'
                  label='Swishkassa'
                />
              </div>
            )}

            {reservationType === 'Paid' && Q_swishStore.data?.swishStore && (
              <div className='flex gap-6'>
                <div className='flex-1'>
                  <FormTextInput
                    label='Bokföringskonto'
                    name='eventReservationDetails.accountNumber'
                    disabled={isEdit}
                    parentForm={form}
                    placeholder='Ange bokföringskonto'
                    maxLength={4}
                    minLength={4}
                  />
                </div>
                <div className='flex-1'>
                  <FormTextInput
                    label='Resultatenhet'
                    name='eventReservationDetails.resultUnit'
                    disabled={isEdit}
                    parentForm={form}
                    placeholder='Ange resultatenhet'
                    maxLength={2}
                    minLength={2}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {/* SECOND SECTION */}
        {reservationType !== 'Open' && (
          <>
            <TicketForm disablePrice={reservationType !== 'Paid'} form={form} />
            <FormTextInput
              label='Antal platser'
              name='eventReservationDetails.numberOfTickets'
              parentForm={form}
              options={{
                pattern: { value: /^\d+$/, message: 'Endast siffror' },
              }}
              placeholder='Antal platser'
              className='w-1/2'
            />

            <RadioButtons
              label='Fråga efter matpreferenser'
              name='eventReservationDetails.gatherFoodPreferences'
              parentForm={form}
              selectOptions={gatherFoodPreferencesOptions}
            />
          </>
        )}
      </div>
    </>
  );
}
