export const testIds = {
  themes: {
    modal: {
      startTime: 'startTime',
      endTime: 'endTime',
      innerText: 'innerText',
    },
  },
  pushNotifications: {
    modal: {
      sentAt: 'sentAt',
    },
  },
  other: {
    loadingSpinner: 'loadingSpinner',
  },
};
