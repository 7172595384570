import { EventObj } from './types';
import { QK } from '../queryKeys';
import { fetchWithErrorThrowing } from 'ck-utils';
import { formatEvents } from './localUtils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useEventsDraft = () =>
  useQuery({
    queryKey: QK.events.draft,
    queryFn: getEventsDraft,
  });

// Query function
export async function getEventsDraft(): Promise<EventObj[]> {
  const res = await fetchWithErrorThrowing('/events/draft', {
    method: 'GET',
  });

  const data: { events: EventObj[] } = await res.json();

  return formatEvents(data.events);
}
