import { Dialog, Transition } from '@headlessui/react';

import { Fragment } from 'react';
import { MainTitle } from 'ck-components';
import { ModalProps } from '../types';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import { xmark } from 'src/assets';

/**
 * A generic modal component with some customized styling and transitions.
 * Renders children inside of it - meaning you can populate the modal with whatever is needed.
 *
 * @param modalTitle the main title of the modal, displayed in bold letters at the top
 * @param isOpen denotes whether or not the modal is currently visible
 * @param setIsOpen a callback used to close the modal when the user presses the close-button
 * @param onClose a callback that executes when the user presses the close-button or dismisses the modal
 * @param children the child components rendered within the modal body
 * @param showCloseButton denotes whether or not the x-button should be visible in the top right corner
 * @param customStyle optional custom styling (can be used to for example set a custom max-width)
 */

export default function Modal({
  modalTitle = '',
  isOpen,
  setIsOpen,
  onClose,
  children,
  showCloseButton = false,
  customStyle,
}: ModalProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={(isOpen) => {
          setIsOpen(isOpen);
          if (onClose) {
            onClose();
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-70 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-0 text-left'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className={clsx(
                  'relative my-8 w-fit max-w-[80%] transform overflow-hidden rounded-4xl  bg-white text-left shadow-xl transition-all',
                  customStyle
                )}
              >
                <div className='overflow-auto bg-white p-10' aria-label='modal'>
                  <div className='flex items-start'>
                    <div className='w-full text-left'>
                      <MainTitle label={modalTitle} />
                      {children}
                    </div>
                    {showCloseButton && (
                      <button
                        onClick={() => {
                          setIsOpen(false);
                          if (onClose) {
                            onClose();
                          }
                        }}
                      >
                        <ReactSVG
                          aria-label={'closeButton'}
                          src={xmark}
                          role={'svg'}
                          className='flex w-9 shrink-0 rounded-full bg-gray-300 stroke-black p-2 hover:cursor-pointer'
                        />
                      </button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
