import {
  ConfirmationModal,
  DropdownOption,
  DropdownSelector,
  Form,
  FormLabel,
  FormMultiLineTextInput,
  FormTextInput,
  SubTitle,
  ToggleRadioButtons,
} from 'ck-components';
import {
  PostPushNotification,
  ScheduledNotification,
  ScheduledNotificationFormatted,
  useNotificationsCreate,
  useNotificationsEdit,
  useTargetGroups,
} from 'ck-queries';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  dateFormatInputCanAccept,
  localStorageTyped,
  useTypedNavigate,
} from 'ck-utils';
import {
  getSavedPushNotificationValues,
  saveInLocalStorage,
} from 'src/utils/saveInLocalStorage';
import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { defaultPushNotificationValues } from './defaults';

interface Props {
  isEdit?: boolean;
  prefill?: ScheduledNotification | ScheduledNotificationFormatted;
  isCopy?: boolean;
}

/**
 * The form used to create push notifications.
 */

export function CreatePushNotificationForm({ prefill, isCopy, isEdit }: Props) {
  // Hooks
  const { navigate } = useTypedNavigate();
  const Q_targetGroups = useTargetGroups();
  const M_CreateNotification = useNotificationsCreate();
  const M_EditNotification = useNotificationsEdit(prefill?.id || '');

  const form = useForm<PostPushNotification>({
    mode: 'onChange',
    defaultValues: {
      ...(prefill ?? getSavedPushNotificationValues()),
    },
  });

  const { reset, watch } = form;

  // Autosave the create input to localStorage every 1/2 sec
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isEdit && !isCopy) {
        saveInLocalStorage('FORM_CREATE_NOTIFICATION', form);
      }
    }, 500);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, isCopy]);

  const scheduleTime = watch('scheduleTime');
  const count = watch('targetGroup.count');

  type ScheduleSelection = 'Schemalägg' | 'Skicka nu';

  const scheduleSelectionOptions: Array<{
    label: string;
    value: ScheduleSelection;
  }> = [
    { label: 'Schemalägg', value: 'Schemalägg' },
    { label: 'Skicka nu', value: 'Skicka nu' },
  ];

  const [scheduleSelection, setScheduleSelection] =
    useState<ScheduleSelection>('Schemalägg');

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const cleanForm = () => {
    localStorageTyped.remove('FORM_CREATE_NOTIFICATION');
    reset(defaultPushNotificationValues);
  };

  const onSubmit: SubmitHandler<PostPushNotification> = async (data) => {
    if (scheduleSelection === 'Skicka nu') {
      data.scheduleTime = null;
    }
    if (isEdit) {
      M_EditNotification.mutateAsync(data).then(() => {
        cleanForm();
        navigate('/push-notifications/archive');
      });
    } else {
      M_CreateNotification.mutateAsync(data).then(() => {
        cleanForm();
        navigate('/push-notifications/archive');
      });
    }
  };

  const onSaveDraft = () => {
    form.setValue('isDraft', true);
    form.handleSubmit(onSubmit)();
  };

  return (
    <div className='max-w-7xl'>
      <Form
        onSubmit={() => setIsConfirmationOpen(true)}
        buttonType='button'
        submitButtonLabel={isEdit ? 'Spara' : 'Gå vidare'}
        submitButtonStyle='w-52'
        trigger={form.trigger}
        onCleanForm={cleanForm}
        onSaveDraft={onSaveDraft}
      >
        {/* TODO: Extract this modal to another component */}
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          setIsOpen={setIsConfirmationOpen}
          onConfirmation={() => {
            form.setValue('isDraft', false);
            form.handleSubmit(onSubmit)();
          }}
          modalTitle={
            scheduleSelection === 'Schemalägg'
              ? 'Schemalägg notis'
              : 'Skicka notis'
          }
          confirmationLabel={scheduleSelection}
        >
          <p className='max-w-md text-lg'>
            {scheduleSelection === 'Skicka nu' &&
              `Är du säker på att du vill skicka en notis till ${count} st?`}
            {scheduleSelection === 'Schemalägg' &&
              !isEdit &&
              `Vill du schemalägga notisen till ${
                scheduleTime && dayjs(scheduleTime).format('D MMMM kl. HH:mm')
              }?`}
            {isEdit &&
              `Detta kommer skriva över den tidigare schemalagda notisen. Vill du spara ändringarna?`}
          </p>
        </ConfirmationModal>
        <div className='flex flex-1 flex-row gap-14'>
          <div className='flex-1'>
            <SubTitle label='Svenska' />
            <FormTextInput
              label={'RUBRIK'}
              parentForm={form}
              maxLength={40}
              name='titleSwedish'
            />
            <FormTextInput
              description={'Visas i pushnotisen'}
              label={'KORT MEDDELANDE'}
              parentForm={form}
              maxLength={100}
              name='notificationMessageSwedish'
            />
            <FormMultiLineTextInput
              label={'LÅNGT MEDDELANDE'}
              description={'Visas i appens inkorgsvy'}
              parentForm={form}
              type='textfield'
              maxLength={1000}
              name='inboxMessageSwedish'
            />
          </div>
          <div className='flex-1'>
            <SubTitle label='English' />
            <FormTextInput
              label={'TITLE'}
              parentForm={form}
              maxLength={40}
              name='titleEnglish'
            />
            <FormTextInput
              label={'SHORT MESSAGE'}
              description={'Displayed in the push notification'}
              parentForm={form}
              maxLength={100}
              name='notificationMessageEnglish'
            />
            <FormMultiLineTextInput
              label={'LONG MESSAGE'}
              description={`Displayed in the app's inbox`}
              parentForm={form}
              maxLength={1000}
              name='inboxMessageEnglish'
            />
          </div>
        </div>
        <hr />
        <div className='flex flex-row  gap-14'>
          <div className='flex-1'>
            {Q_targetGroups.data && (
              <DropdownSelector
                label='VISAS FÖR'
                name='targetGroup'
                defaultValue={Q_targetGroups.data[0]}
                emptyStateLabel={'Laddar...'}
                parentForm={form}
                onChange={(selected: DropdownOption) =>
                  form.setValue('targetGroup', selected)
                }
                optionsData={Q_targetGroups.data || []}
              />
            )}
          </div>
          {/* Invisible filler to get column width correct */}
          <div className='invisible flex-1' />
        </div>
        <FormLabel label={'TID FÖR UTSKICK'}>
          <>
            <ToggleRadioButtons
              selectedValue={scheduleSelection}
              selectOptions={scheduleSelectionOptions}
              disabled={Boolean(isEdit)}
              onChange={(e) =>
                setScheduleSelection(e.target.value as ScheduleSelection)
              }
            />
            {scheduleSelection === 'Schemalägg' && (
              <div className='mt-5 w-60'>
                <FormTextInput
                  parentForm={form}
                  maxLength={100}
                  minDate={dayjs().format(dateFormatInputCanAccept)}
                  name='scheduleTime'
                  type='datetime-local'
                  options={{
                    validate: (value) =>
                      dayjs(value).isAfter(dayjs()) ||
                      'Datumet måste vara i framtiden',
                  }}
                />
              </div>
            )}
          </>
        </FormLabel>
      </Form>
    </div>
  );
}
