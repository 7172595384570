import { Tag } from './Tag';

interface Props {
  isDraft?: boolean;
}

// Simple info component for showing the title and id of the entity being edited
export const EditInfo = ({ isDraft }: Props) => {
  if (!isDraft) {
    return <></>;
  }
  return (
    <div className='flex gap-8'>
      {isDraft && (
        <Tag
          label='Utkast'
          customStyle='bg-sky-100 border-2 border-sky-500 px-4 h-9'
          customTextStyle='text-sky-500 uppercase'
        />
      )}
    </div>
  );
};
