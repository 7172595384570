export function capitalizeString(str: string | undefined) {
  if (!str) return '';

  // If the string is already capitalized, return it
  if (
    str[0] === str[0].toUpperCase() &&
    str.slice(1) === str.slice(1).toLowerCase()
  ) {
    return str;
  }

  // Capitalize the first letter and make the rest of the string lowercase
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}
