import { DivisionFormatted, DivisionFromServer } from './types';

import { QK } from 'src/queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useAuth } from '../auth/useAuth';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useDivisions = () => {
  const { user } = useAuth();

  const isSuperAdmin = user?.roles.includes('SuperAdmin');

  return useQuery({
    queryKey: [QK.divisions],
    queryFn: getDivisions,
    enabled: isSuperAdmin,
  });
};

// Query function
export async function getDivisions(): Promise<DivisionFormatted[]> {
  const res = await fetchWithErrorThrowing(`/divisions`, {
    method: 'GET',
  });

  const data: { divisions: DivisionFromServer[] } = await res.json();

  return formatDivisions(data.divisions);
}

// Query formatting
// Formatting to add 'st' to the end of the number of committees and admins
const formatDivisions = (divisions: DivisionFromServer[]) =>
  divisions.map(
    (d) =>
      ({
        ...d,
        name: d.name.length <= 4 ? `${d.name}-sektionen` : d.name,
        committees: `${d.committees} st`,
        admins: `${d.admins} st`,
      } as DivisionFormatted)
  );
