export type ImageInLocalStorage = {
  base64String: string;
  name?: string;
  type?: string;
};

export const convertBase64ImageToFile = (image: ImageInLocalStorage) => {
  // Step 1: Convert Base64 string to Uint8Array
  const binaryString = window.atob(image.base64String.split(',')[1]);
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  // Step 2: Create Blob from Uint8Array
  const blob = new Blob([byteArray], { type: image.type });

  // Step 3: Create File from Blob with name and type
  return new File([blob], image.name || '', {
    type: image.type,
  });
};
