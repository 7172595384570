import { Tooltip as ImportedToolTip } from 'react-tooltip';

interface Props {
  id: string;
  content: string;
  children: React.ReactElement | React.ReactElement[];
}

export const OurTooltip = ({ id, content, children }: Props) => {
  return (
    <>
      <ImportedToolTip id={id} />
      <a data-tooltip-content={content} data-tooltip-id={id}>
        {children}
      </a>
    </>
  );
};
