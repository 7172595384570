import { createFormData, fetchWithErrorThrowing } from 'ck-utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GK } from '../queryKeys';
import { PostCreateEventV1 } from './types';
import toast from 'react-hot-toast';

export const useEventsCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PostCreateEventV1) =>
      toast.promise(postCreateEvent(data), {
        loading: 'Laddar...',
        success: 'Event skapat',
        error: 'Kunde inte skapa eventet',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: GK.events,
      });
    },
  });
};

async function postCreateEvent(data: PostCreateEventV1) {
  const formData = createFormData(data);

  return fetchWithErrorThrowing('/events', {
    method: 'POST',
    body: formData,
  });
}
