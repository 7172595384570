import clsx from 'clsx';

interface Props {
  label: string;
  text?: string | null;
  className?: string;
}

export const ModalLabelAndText = ({ label, text, className }: Props) => (
  <div className={clsx('max-w-2xl', className)}>
    <h3 className='mb-1 font-light uppercase tracking-custom'>{label}</h3>
    {text && <p>{text}</p>}
  </div>
);
