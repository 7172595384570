import { LoadingSpinner, MainTitle, TableWithPagination } from 'ck-components';

import { DivisionFormatted } from 'src/queries/organisations/types';
import { useDivisions } from 'src/queries';
import { useNavigate } from 'react-router-dom';

export function Organisations() {
  const navigate = useNavigate();
  const Q_divisions = useDivisions();

  return (
    <div className='flex w-full gap-10'>
      <div className='flex w-full flex-col gap-6'>
        {/* Following div avoids layout shift for the MainTitle between Organistion -> Division */}
        <div className='h-6' />
        <MainTitle label='Organisationer' />
        {Q_divisions.isLoading && (
          <div className='mt-20 flex w-full items-center justify-center'>
            <LoadingSpinner width={100} />
          </div>
        )}
        {Q_divisions.isError && (
          <div>
            Något gick fel vid inläsning av organisationer. {<br />}
            Prova uppdatera webbläsaren.
          </div>
        )}
        {Q_divisions.isSuccess && (
          <TableWithPagination
            data={Q_divisions.data}
            elementsPerPage={20}
            tableColHeadings={['Organisationer', 'Kommittéer', 'Admin']}
            tableRowDataPaths={['name', 'committees', 'admins']}
            onRowPress={(data: DivisionFormatted) =>
              navigate(`/organisations/${data.id}`)
            }
          />
        )}
      </div>
    </div>
  );
}
