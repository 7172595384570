import { QK } from '../queryKeys';
import { ScheduledNotification } from './types';
import { fetchWithErrorThrowing } from 'ck-utils';
import { formatScheduledPushNotifications } from './localUtils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useNotificationsScheduled = () => {
  return useQuery({
    queryKey: QK.notifications.prefill,
    queryFn: getNotificationsScheduled,
  });
};

// Query function
export const getNotificationsScheduled = () =>
  fetchWithErrorThrowing('/notifications/scheduled', {
    method: 'GET',
  })
    .then((res) => res.json())
    .then(
      (d: { scheduledNotifications: ScheduledNotification[] }) =>
        d.scheduledNotifications
    );

// Optional query function with formatting
export const getNotificationsScheduledFormatted = () =>
  getNotificationsScheduled().then((notifications) =>
    formatScheduledPushNotifications(notifications)
  );
